import { Accordion } from '@zendeskgarden/react-accordions'
import { Paragraph, UnorderedList, XL, MD } from '@zendeskgarden/react-typography'
import React, { useContext, useState } from 'react'
import { NavExpandedContext } from './Root'
import { Head, HeaderCell, HeaderRow, Table, Body, Cell, Row } from '@zendeskgarden/react-tables'
import { Field, Input, Label } from '@zendeskgarden/react-forms'
import styled from 'styled-components'

const StyledInput = styled(Input)`
  margin: 0.5rem;
  padding: 0.5rem;
  max-width: 20vmin;
`

const SupplyDiceTable = () => {
  const [itemPrice, setItemPrice] = useState(50)

  return (
    <>
      <Field>
        <Label>Cost of 1 item</Label>
        <StyledInput type="number" value={itemPrice} onChange={(e) => setItemPrice(e.target.value)} />
      </Field>
      <Table>
        <Head>
          <HeaderRow>
            <HeaderCell>Tier</HeaderCell>
            <HeaderCell>Die Sides</HeaderCell>
            <HeaderCell>Avg Left</HeaderCell>
            <HeaderCell>To Buy</HeaderCell>
            {/* <HeaderCell>To Upgrade</HeaderCell> */}
          </HeaderRow>
        </Head>
        <Body>
          <Row>
            <Cell>
            6
            </Cell>
            <Cell>
            d20
            </Cell>
            <Cell>
            30
            </Cell>
            <Cell>
              {itemPrice * 30}
            </Cell>
            {/* <Cell>
              {itemPrice * 10}
            </Cell> */}
          </Row>
          <Row>
            <Cell>
            5
            </Cell>
            <Cell>
            d12
            </Cell>
            <Cell>
            20
            </Cell>
            <Cell>
              {itemPrice * 20}
            </Cell>
            {/* <Cell>
              {itemPrice * 6}
            </Cell> */}
          </Row>
          <Row>
            <Cell>
            4
            </Cell>
            <Cell>
            d10
            </Cell>
            <Cell>
            14
            </Cell>
            <Cell>
              {itemPrice * 14}
            </Cell>
            {/* <Cell>
              {itemPrice * 5}
            </Cell> */}
          </Row>
          <Row>
            <Cell>
            3
            </Cell>
            <Cell>
            d8
            </Cell>
            <Cell>
            9
            </Cell>
            <Cell>
              {itemPrice * 9}
            </Cell>
            {/* <Cell>
              {itemPrice * 4}
            </Cell> */}
          </Row>
          <Row>
            <Cell>
            2
            </Cell>
            <Cell>
            d6
            </Cell>
            <Cell>
            5
            </Cell>
            <Cell>
              {itemPrice * 5}
            </Cell>
            {/* <Cell>
              {itemPrice * 3}
            </Cell> */}
          </Row>
          <Row>
            <Cell>
            1
            </Cell>
            <Cell>
            d4
            </Cell>
            <Cell>
            2
            </Cell>
            <Cell>
              {itemPrice * 2}
            </Cell>
            {/* <Cell>
              {itemPrice * 2}
            </Cell> */}
          </Row>
        </Body>
      </Table>
    </>
  )
}

export const RulesPage = () => {
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(false)

  return (
    <Paragraph>
      <Accordion isExpandable defaultExpandedSections={[0, 1, 2, 3]}>
        <Accordion.Section>
          <Accordion.Header>
            <XL>{'Combat'}</XL>
          </Accordion.Header>
          <Accordion.Panel>
            <Paragraph>
              <UnorderedList>
                <UnorderedList.Item>
                  <MD>{'Shoving, Jumping, and drinking a Potion are all Bonus Actions'}</MD>
                </UnorderedList.Item>
                <UnorderedList.Item>
                  <MD>{'The help action can bring a downed ally back to 1 hp'}</MD>
                </UnorderedList.Item>
                <UnorderedList.Item>
                  <MD>{'Coming back from being downed consumes your next action'}</MD>
                </UnorderedList.Item>
                <UnorderedList.Item>
                  <MD>{'Spare the dying is now a bonus action'}</MD>
                </UnorderedList.Item>
              </UnorderedList>
            </Paragraph>
          </Accordion.Panel>
        </Accordion.Section>
        <Accordion.Section>
          <Accordion.Header>
            <XL>{'Rest'}</XL>
          </Accordion.Header>
          <Accordion.Panel>
            <Paragraph>
              <UnorderedList>
                <UnorderedList.Item>
                  <MD>{'There are no Hit Dice, but Short Rests restore half of each character\'s max HP'}</MD>
                </UnorderedList.Item>
                <UnorderedList.Item>
                  <MD>{'Short Rests only require 10 minutes'}</MD>
                </UnorderedList.Item>
                <UnorderedList.Item>
                  <MD>{'The party can only Short Rest twice between each Long Rest'}</MD>
                </UnorderedList.Item>
                <UnorderedList.Item>
                  <MD>{'Long Rests require consuming a ration/eating a meal'}</MD>
                </UnorderedList.Item>
                <UnorderedList.Item>
                  <MD>{'The Bard\'s Song of Rest allows the party to take an additional Short Rest per Long Rest'}</MD>
                </UnorderedList.Item>
              </UnorderedList>
            </Paragraph>
          </Accordion.Panel>
        </Accordion.Section>
        <Accordion.Section>
          <Accordion.Header>
            <XL>{'Spell Scrolls'}</XL>
          </Accordion.Header>
          <Accordion.Panel>
            <Paragraph>
              <MD>{'Spell Scrolls can be used by anyone regardless of your class, or spell slot level.'}</MD>
            </Paragraph>
          </Accordion.Panel>
        </Accordion.Section>
        <Accordion.Section>
          <Accordion.Header>
            <XL>{'Supply Dice'}</XL>
          </Accordion.Header>
          <Accordion.Panel>
            <Paragraph>
              <MD>{'Supply Dice are a way of abstracting the amount of something you have.'}</MD>
              <MD>{'When consuming an item, roll the die. If you get a 1 or a 2, downgrade the die to a lower tier.'}</MD>
              <MD>{'It adds an element of randomness, but also simplifies keeping track of large quantities.'}</MD>
              <MD>{'To acquire a supply die of an item, pay the purchase cost. To increase the tier pay the difference in cost.'}</MD>
            </Paragraph>
            <Paragraph>
              <SupplyDiceTable />
            </Paragraph>
          </Accordion.Panel>
        </Accordion.Section>
      </Accordion>
    </Paragraph>
  )
}
