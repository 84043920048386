import React, { useContext } from 'react'
import { dataWithIds } from '../Data.js'
import { Link } from 'react-router-dom'
import { SM, MD, Paragraph } from '@zendeskgarden/react-typography'
import { Avatar } from '@zendeskgarden/react-avatars'
import styled, { ThemeContext } from 'styled-components'
import { NavExpandedContext } from './Root.js'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'

const BoldStyledName = styled(MD).attrs({ tag: 'span', isBold: true })`
  margin-left: 0.5rem;
`

const StyledName = styled(MD).attrs({ tag: 'span', isMonospace: true })`
  margin-left: 0.5rem;
`

const StyledColumn = styled(Col)`
  margin-bottom: 1rem;
`

/**
 *
 * @param {string} dataKey
 * @param {boolean} square
 * @returns {JSX.Element} directory
 */
export const Directory = ({ dataKey, square = false }) => {
  const theme = useContext(ThemeContext)
  const specificData = dataWithIds[dataKey]
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(true)

  return (
    <Grid>
      <Row>
        {specificData.map((item) => {
          return (
            <StyledColumn sm={12} md={6} lg={4} xl={3} key={item.id}>
              <Paragraph>
                <Link to={`/${dataKey}/${item.id}`}>
                  <Avatar size="large" backgroundColor={item.image ? theme.palette.grey[100] : theme.palette.grey[600]} isSystem={square}>
                    {item.image
                      ? item.image
                      : <Avatar.Text>
                        {item.name.split(' ').map((word) => word[0]).join('')}
                      </Avatar.Text>
                    }
                  </Avatar>
                  {item.description
                    ? (
                      <BoldStyledName>
                        {item.name}
                      </BoldStyledName>
                    )
                    : (
                      <StyledName>
                        {item.name}
                      </StyledName>
                    )}
                  {item.summary && (
                    <SM>
                      {item.summary}
                    </SM>
                  )}
                </Link>
              </Paragraph>
            </StyledColumn>
          )
        })}
      </Row>
    </Grid>
  )
}
