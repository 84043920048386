import React from 'react'
import { Link } from 'react-router-dom'

/**
 *
 * @param {string} label
 * @param {string} to
 * @param {string} relative
 * @returns {JSX.Element} labelLink
 */
export const LabelLink = ({ label, to, relative }) => {
  return (
    <>
      {
        to
          ? <Link to={to} relative={relative || 'route'}>
            {label}
          </Link>
          : <span>{label}</span>
      }
    </>
  )
}
