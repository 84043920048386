import styled from 'styled-components'

export const HalfImage = styled.img`
  height: 128px;
  width: 128px;
`

export const FullImage = styled.img`
  height: 256px;
  width: 256px;
`

export const DoubleImage = styled.img`
    height: 512px;
    width: 512px;
`
