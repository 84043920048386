import React, { useState } from 'react'
import { Combobox, Option, Field, Label } from '@zendeskgarden/react-dropdowns.next'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { useSearch } from '../hooks/useSearch'
import styled from 'styled-components'

// set max width to height of page and center it
const StyledField = styled(Field)`
  max-width: 60vmin;
  margin: auto;
`

/**
 *
 * @returns {JSX.Element} search element
 */
export const SearchElement = () => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')
  const options = useSearch(searchTerm)

  const handleChange = (changeEvent) => {
    if (changeEvent.selectionValue) {
      navigate(changeEvent.selectionValue)
      return
    }
    if (changeEvent.inputValue !== undefined && changeEvent.inputValue !== null) {
      setSearchTerm(changeEvent.inputValue)
    }
  }

  return (
    <StyledField>
      <Label>Search</Label>
      <Combobox isAutocomplete startIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />} onChange={handleChange}>
        {
          options.map((item) => {
            return <Option key={item.link} value={item.link} label={item.name} />
          })
        }
      </Combobox>
    </StyledField>
  )
}
