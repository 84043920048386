import { Tag } from '@zendeskgarden/react-tags'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

/**
 * @param {object} props
 * @param {number} props.sentiment - A number between -10 and 10
 *
 * @returns {JSX.Element} sentiment
 */
export const Sentiment = ({ sentiment }) => {
  const { palette } = useContext(ThemeContext)

  let sentimentColor = palette.grey[800]
  let sentimentLabel = 'Unknown'

  if (sentiment === 0) {
    sentimentColor = palette.grey[100]
    sentimentLabel = 'Neutral'
  } else if (sentiment > 0) {
    sentimentColor = palette.green[Math.min(800, sentiment * 100)]
    if (sentiment <= 4) {
      sentimentLabel = 'Neutral'
    } else {
      sentimentLabel = 'Positive'
    }
  } else if (sentiment < 0) {
    sentimentColor = palette.red[Math.min(800, sentiment * -100)]
    if (sentiment >= -4) {
      sentimentLabel = 'Neutral'
    } else {
      sentimentLabel = 'Negative'
    }
  }

  return (
    <Tag hue={sentimentColor}>
      {sentimentLabel}
    </Tag>
  )
}
