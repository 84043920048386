import React from 'react'
import { partyLevelWealth } from '../data/Items'
import { Paragraph, XL } from '@zendeskgarden/react-typography'

export const PartyWealth = () => {
  return (
    <div>
      {partyLevelWealth.map((level, index) => {
        if (index > 0 && index < 21) {
          return (
            <Paragraph key={index}>
              <XL>Level {index}</XL>
              <XL>Gold {level}</XL>
            </Paragraph>)
        } else {
          return null
        }
      })
      }
    </div>
  )
}
