import { dataWithIds } from '../Data'

/**
 * @param {string} factionId
 * @returns {object} faction
 */
export const getFaction = (factionId) => {
  const factions = dataWithIds.factions
  const faction = factions.find(faction => faction.id === factionId)
  const factionType = {
    id: factionId.id ?? '',
    name: faction.name || '',
    description: faction.description || '',
    image: faction.image || null,
    members: [
      ...faction.members.map(member => {
        return {
          id: member.id ?? '',
          name: member.name || '',
          description: member.description || '',
          image: member.image || null
        }
      })
    ],
    totalSentiment: faction.totalSentiment || ''
  }
  return factionType
}
