import React from 'react'
import { MD, Paragraph } from '@zendeskgarden/react-typography'
import { Status } from './Status'
import { Sentiment } from './Sentiment'

export const Statuses = ({ status, sentiment }) => {
  return (
    <Paragraph>
      {status && <MD>Status: <Status status={status} /></MD>}
      {status !== 'dead' && <MD>Sentiment: <Sentiment sentiment={sentiment} /></MD>}
    </Paragraph>
  )
}
