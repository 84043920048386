import React from 'react'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { createRoot } from 'react-dom/client'
import '@zendeskgarden/css-bedrock'

const root = document.getElementById('root')
createRoot(root).render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
