import React, { useContext } from 'react'
import { Timeline } from '@zendeskgarden/react-accordions'
import styled from 'styled-components'
import { MD, Paragraph, Span } from '@zendeskgarden/react-typography'
import { dataWithIds } from '../Data'

import { NavExpandedContext } from './Root'
import { Description } from '../components/Description'

const ContentHeader = styled(Span).attrs({ isBold: true })`
  display: block;
`
/**
 * @param {object} eventData
 *
 * @returns {JSX.Element} event
 */
const Event = ({ eventData }) => {
  const { header, content } = eventData
  return (
    <Timeline.Item>
      <Timeline.Content>
        <ContentHeader>
          {header}
        </ContentHeader>
        <Paragraph>
          <Description description={content} />
        </Paragraph>
        {(eventData.charactersMet)
          ? <>
            <Paragraph>
              <MD isBold>Characters met:</MD>
            </Paragraph>
            <Paragraph>
              {
                eventData.charactersMet?.map((c, index) => {
                  return (
                    <>
                      <Description key={c} description={c} />
                      {index !== eventData.charactersMet.length - 1 ? ', ' : ''}
                    </>)
                })
              }
            </Paragraph>
          </>
          : null
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

/**
 *
 * @returns {JSX.Element} history page
 */
export const HistoryPage = () => {
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(false)
  return (
    <Timeline isAlternate>
      {dataWithIds.events.map(e => <Event key={e.id} eventData={e} />)}
    </Timeline>
  )
}
