import React, { useContext } from 'react'
import { Body, Chrome, Content, Header, Main, Nav, NavItem, NavItemIcon, NavItemText } from '@zendeskgarden/react-chrome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faUser, faPeopleGroup, faPersonPraying, faMapLocationDot, faTimeline, faShip, faScaleBalanced, faStore } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'

const ThinnerNav = styled(Nav)`
  max-width: 160px;
`

/**
 * @param {object} props
 * @param {boolean} props.isExpanded
 * @param {JSX.Element} props.children
 *
 * @returns {JSX.Element} navbar
 */
export const Navbar = ({ isExpanded, children }) => {
  const { palette } = useContext(ThemeContext)
  const location = useLocation()

  return (
    <Chrome isFluid hue={palette.blue[800]}>
      <ThinnerNav isExpanded={isExpanded && location.pathname.split('/').length <= 2}>
        <NavigationItem icon={faHouse} url="/">
            Home
        </NavigationItem>
        <NavigationItem icon={faUser} url="/characters">
            Characters
        </NavigationItem>
        <NavigationItem icon={faPersonPraying} url="/deities">
            Deities
        </NavigationItem>
        <NavigationItem icon={faPeopleGroup} url="/factions">
            Factions
        </NavigationItem>
        <NavigationItem icon={faTimeline} url="/history">
            History
        </NavigationItem>
        <NavigationItem icon={faMapLocationDot} url="/locations">
            Locations
        </NavigationItem>
        {/* <NavigationItem icon={faShip} url="/ships">
            Ships
        </NavigationItem> */}
        <NavigationItem icon={faScaleBalanced} url="/rules">
            Rules
        </NavigationItem>
        <NavigationItem icon={faStore} url="/shop">
            Shop
        </NavigationItem>
      </ThinnerNav>
      <Body>
        <Header />
        <Content>
          <Main>
            {children}
          </Main>
        </Content>
      </Body>
    </Chrome>
  )
}

const NavigationItem = ({ icon, url, children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isActive = (location.pathname.includes(url) && url !== '/') || location.pathname === url

  return (
    <NavItem isCurrent={isActive} onClick={() => navigate(url)}>
      <NavItemIcon>
        <FontAwesomeIcon icon={icon} />
      </NavItemIcon>
      <NavItemText>
        {children}
      </NavItemText>
    </NavItem>
  )
}
