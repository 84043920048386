import { Tag } from '@zendeskgarden/react-tags'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export const Alignment = ({ alignmentInitials }) => {
  const { palette } = useContext(ThemeContext)

  const getColorAndLabel = (alignmentInitials) => {
    switch (alignmentInitials) {
    case 'CE':
      return [palette.purple[600], 'Chaotic Evil']
    case 'CN':
      return [palette.grey[800], 'Chaotic Neutral']
    case 'CG':
      return [palette.azure[600], 'Chaotic Good']
    case 'NE':
      return [palette.red[800], 'Neutral Evil']
    case 'TN':
      return [palette.grey[100], 'True Neutral']
    case 'NG':
      return [palette.orange[600], 'Neutral Good']
    case 'LE':
      return [palette.red[600], 'Lawful Evil']
    case 'LN':
      return [palette.blue[600], 'Lawful Neutral']
    case 'LG':
      return [palette.yellow[300], 'Lawful Good']
    default:
      return [palette.grey[400], 'Unknown']
    }
  }

  const [alignmentColor, alignmentLabel] = getColorAndLabel(alignmentInitials)

  return (
    <Tag hue={alignmentColor}>
      {alignmentLabel}
    </Tag>
  )
}
