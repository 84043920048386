import { dataWithoutImages } from '../Data'
import React from 'react'

export const Export = () => {
  // json stringify data
  const data = JSON.stringify(dataWithoutImages(), null, 2)
  return (
    <div>
      <h1>Export</h1>
      <p>Export data to JSON</p>
      <textarea
        value={data}
        readOnly
        style={{ width: '100%', height: '600px' }}
      />
    </div>
  )
}
