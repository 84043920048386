import React from 'react'
import { Link } from 'react-router-dom'

/**
 *
 * @param {string} text
 * @returns {object[]} elements
 */
const bracketsToLinks = (text) => {
  const elements = []
  let textToParse = text

  while (textToParse) {
    const bracketIndex = textToParse.indexOf('[')
    const bracketEndIndex = textToParse.indexOf(']')
    const linkIndex = textToParse.indexOf('(')
    const linkEndIndex = textToParse.indexOf(')')

    if (bracketIndex === -1 || bracketEndIndex === -1 || linkIndex === -1 || linkEndIndex === -1) {
      elements.push({ type: 'text', value: textToParse })
      break
    }

    const textBeforeBracket = textToParse.slice(0, bracketIndex)
    const textAfterLink = textToParse.slice(linkEndIndex + 1)
    const linkText = textToParse.slice(bracketIndex + 1, bracketEndIndex)
    const linkHref = textToParse.slice(linkIndex + 1, linkEndIndex)

    textBeforeBracket && elements.push({ type: 'text', value: textBeforeBracket })
    linkText && linkHref && elements.push({ type: 'link', value: linkText, href: linkHref })

    textToParse = textAfterLink
  }

  return elements
}

/**
 *
 * @param {string} description
 * @returns {JSX.Element} description
 */
export const Description = ({ description }) => {
  const elements = bracketsToLinks(description)
  return (
    <>
      {elements.map((element, index) => {
        if (element.type === 'text') {
          return <span key={index}>{element.value}</span>
        } else if (element.type === 'link') {
          return <Link key={index} to={element.href}>{element.value}</Link>
        } else {
          return null
        }
      })}
    </>
  )
}
