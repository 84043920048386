import React, { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Navbar } from '../components/Navbar'
import { ThemeProvider, DEFAULT_THEME } from '@zendeskgarden/react-theming'
import styled from 'styled-components'

const OutletWrapper = styled.div`
  padding: 0.5rem;
  margin: 0.5rem;
`

const theme = {
  ...DEFAULT_THEME
}

export const NavExpandedContext = createContext(() => {})

/**
 *
 * @returns {JSX.Element} root
 */
export const Root = () => {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <ThemeProvider theme={theme}>
      <NavExpandedContext.Provider value={setIsExpanded}>
        <Navbar isExpanded={isExpanded} >
          <OutletWrapper>
            <Outlet />
          </OutletWrapper>
        </Navbar>
      </NavExpandedContext.Provider>
    </ThemeProvider>
  )
}
