import rng from '../utils/PRNG'

export const consumableItems = [
  {
    name: 'Spell Scroll Level 0',
    price: 10,
    rarity: 'Common',
    link: 'spell-scroll-0-cantrip',
    description: 'Choose a spell from any spell list.',
    toolsLink: 'spell%20scroll%20(cantrip)'
  },
  {
    name: 'Ammunition +1 (each)',
    price: 25,
    rarity: 'Uncommon',
    link: 'ammunition-1',
    toolsLink: '%2b1%20ammunition'
  },
  {
    name: 'Potion of Healing',
    price: 50,
    rarity: 'Common'
  },
  {
    name: "Quaal's Feather Token Anchor",
    price: 50,
    rarity: 'Rare',
    link: 'feather-token-anchor',
    toolsLink: 'quaal\'s%20feather%20token%2c%20anchor'
  },
  {
    name: 'Spell Scroll Level 1',
    price: 60,
    rarity: 'Common',
    link: 'spell-scroll-1st-level',
    toolsLink: 'spell%20scroll%20(1st%20level)',
    description: 'Choose a spell from any spell list.'
  },
  {
    name: 'Philter of Love',
    price: 90,
    rarity: 'Uncommon'
  },
  {
    name: 'Ammunition +2 (ea)',
    price: 100,
    rarity: 'Rare',
    link: 'ammunition-2'
  },
  {
    name: 'Potion of Poison',
    price: 100,
    rarity: 'Uncommon'
  },
  {
    name: 'Dust of Dryness (1 pellet)',
    price: 120,
    rarity: 'Uncommon',
    link: 'dust-of-dryness',
    toolsLink: 'dust%20of%20dryness'
  },
  {
    name: 'Elixir of Health',
    price: 120,
    rarity: 'Rare'
  },
  {
    name: "Keoghtom's Ointment (Per dose)",
    price: 120,
    rarity: 'Uncommon',
    link: 'keoghtoms-ointment'
  },
  {
    name: 'Spell Scroll Level 2',
    price: 120,
    rarity: 'Uncommon',
    link: 'spell-scroll-2nd-level',
    description: 'Choose a spell from any spell list.',
    toolsLink: 'spell%20scroll%20(2nd%20level)'
  },
  {
    name: 'Potion of Fire Breath',
    price: 150,
    rarity: 'Uncommon'
  },
  {
    name: 'Potion of Greater Healing',
    price: 150,
    rarity: 'Uncommon',
    link: 'potion-of-healing-greater'
  },
  {
    name: 'Potion of Climbing',
    price: 180,
    rarity: 'Common'
  },
  {
    name: 'Potion of Heroism',
    price: 180,
    rarity: 'Rare'
  },
  {
    name: 'Potion of Invisibility',
    price: 180,
    rarity: 'Very Rare'
  },
  {
    name: 'Potion of Mind Reading',
    price: 180,
    rarity: 'Rare'
  },
  {
    name: 'Potion of Water Breathing',
    price: 180,
    rarity: 'Uncommon'
  },
  {
    name: 'Scroll of Protection',
    price: 180,
    rarity: 'Rare'
  },
  {
    name: "Nolzur's Marvelous Pigments",
    price: 200,
    rarity: 'Very Rare',
    link: 'marvelous-pigments'
  },
  {
    name: 'Potion of Animal Friendship',
    price: 200,
    rarity: 'Uncommon'
  },
  {
    name: 'Spell Scroll Level 3',
    price: 200,
    rarity: 'Uncommon',
    link: 'spell-scroll-3rd-level',
    toolsLink: 'spell%20scroll%20(3rd%20level)',
    description: 'Choose a spell from any spell list.'
  },
  {
    name: "Quaal's Feather Token Fan",
    price: 250,
    rarity: 'Rare',
    link: 'feather-token-fan',
    toolsLink: 'quaal\'s%20feather%20token%2c%20fan'
  },
  {
    name: "Quaal's Feather Token Whip",
    price: 250,
    rarity: 'Rare',
    link: 'feather-token-whip',
    toolsLink: 'quaal\'s%20feather%20token%2c%20whip'
  },
  {
    name: 'Potion of Diminution',
    price: 270,
    rarity: 'Rare'
  },
  {
    name: 'Potion of Growth',
    price: 270,
    rarity: 'Uncommon'
  },
  {
    name: 'Dust of Disappearance',
    price: 300,
    rarity: 'Uncommon'
  },
  {
    name: 'Necklace of Fireballs (One bead)',
    price: 300,
    rarity: 'Rare',
    link: 'necklace-of-fireballs',
    toolsLink: 'necklace%20of%20fireballs'
  },
  {
    name: 'Potion of Gaseous Form',
    price: 300,
    rarity: 'Rare'
  },
  {
    name: 'Potion of Resistance',
    price: 300,
    rarity: 'Uncommon'
  },
  {
    name: 'Universal Solvent',
    price: 300,
    rarity: 'Legendary'
  },
  {
    name: 'Spell Scroll Level 4',
    price: 320,
    rarity: 'Rare',
    link: 'spell-scroll-4th-level',
    description: 'Choose a spell from any spell list.',
    toolsLink: 'spell%20scroll%20(4th%20level)'
  },
  {
    name: 'Ammunition +3 (ea)',
    price: 400,
    rarity: 'Very Rare',
    link: 'ammunition-3',
    toolsLink: '%2b3%20ammunition'
  },
  {
    name: 'Potion of Speed',
    price: 400,
    rarity: 'Very Rare'
  },
  {
    name: 'Sovereign Glue',
    price: 400,
    rarity: 'Legendary'
  },
  {
    name: 'Horn of Blasting',
    price: 450,
    rarity: 'Rare'
  },
  {
    name: 'Potion of Superior Healing',
    price: 450,
    rarity: 'Very Rare',
    link: 'potion-of-healing-superior'
  },
  {
    name: 'Dust of Sneezing and Choking',
    price: 480,
    rarity: 'Uncommon'
  },
  {
    name: 'Necklace of Fireballs (Two beads)',
    price: 480,
    rarity: 'Rare',
    link: 'necklace-of-fireballs'
  },
  {
    name: 'Oil of Slipperiness',
    price: 480,
    rarity: 'Uncommon'
  },
  {
    name: 'Potion of Flying',
    price: 500,
    rarity: 'Very Rare'
  },
  {
    name: 'Arrow of Slaying (ea)',
    price: 600,
    rarity: 'Very Rare',
    link: 'arrow-of-slaying',
    toolsLink: 'arrow%20of%20slaying%20(*)'
  },
  {
    name: 'Spell Scroll Level 5',
    price: 640,
    rarity: 'Rare',
    link: 'spell-scroll-5th-level',
    description: 'Choose a spell from any spell list.'
  },
  {
    name: 'Bead of Force',
    price: 960,
    rarity: 'Rare'
  },
  {
    name: 'Elemental Gem',
    price: 960,
    rarity: 'Uncommon'
  },
  {
    name: 'Necklace of Fireballs (Three beads)',
    price: 960,
    rarity: 'Rare'
  },
  {
    name: 'Potion of Clairvoyance',
    price: 960,
    rarity: 'Rare'
  },
  {
    name: 'Potion of Vitality',
    price: 960,
    rarity: 'Very Rare'
  },
  {
    name: 'Spell Scroll Level 6',
    price: 1280,
    rarity: 'Very Rare',
    link: 'spell-scroll-6th-level',
    description: 'Choose a spell from any spell list.'
  },
  {
    name: 'Potion of Supreme Healing',
    price: 1350,
    rarity: 'Very Rare',
    link: 'potion-of-healing-supreme'
  },
  {
    name: 'Chime of Opening',
    price: 1500,
    rarity: 'Rare'
  },
  {
    name: 'Necklace of Fireballs (Four beads)',
    price: 1600,
    rarity: 'Rare'
  },
  {
    name: 'Oil of Etherealness',
    price: 1920,
    rarity: 'Rare'
  },
  {
    name: 'Ioun Stone Absorption',
    price: 2400,
    rarity: 'Very Rare'
  },
  {
    name: 'Spell Scroll Level 7',
    price: 2560,
    rarity: 'Very Rare',
    link: 'spell-scroll-7th-level',
    toolsLink: 'spell%20scroll%20(7th%20level)',
    description: 'Choose a spell from any spell list.'
  },
  {
    name: "Quaal's Feather Token Bird",
    price: 3000,
    rarity: 'Rare',
    link: 'feather-token-bird'
  },
  {
    name: "Quaal's Feather Token Swan Boat",
    price: 3000,
    rarity: 'Rare',
    link: 'feather-token-swan-boat'
  },
  {
    name: 'Oil of Sharpness',
    price: 3200,
    rarity: 'Very Rare'
  },
  {
    name: 'Necklace of Fireballs (Five beads)',
    price: 3840,
    rarity: 'Rare'
  },
  {
    name: 'Potion of Invulnerability',
    price: 3840,
    rarity: 'Rare'
  },
  {
    name: 'Gem of Brightness',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Spell Scroll Level 8',
    price: 5120,
    rarity: 'Very Rare',
    link: 'spell-scroll-8th-level',
    description: 'Choose a spell from any spell list.'
  },
  {
    name: 'Deck of Illusions',
    price: 6120,
    rarity: 'Uncommon'
  },
  {
    name: 'Necklace of Fireballs (Six beads)',
    price: 7680,
    rarity: 'Rare'
  },
  {
    name: 'Spell Scroll Level 9',
    price: 10240,
    rarity: 'Legendary',
    link: 'spell-scroll-9th-level',
    description: 'Choose a spell from any spell list.'
  },
  {
    name: 'Ioun Stone Greater Absorption',
    price: 31000,
    rarity: 'Legendary'
  },
  {
    name: 'Rod of Absorption',
    price: 50000,
    rarity: 'Very Rare'
  },
  {
    name: 'Talisman of Ultimate Evil',
    price: 61440,
    rarity: 'Legendary'
  },
  {
    name: 'Talisman of Pure Good',
    price: 71680,
    rarity: 'Legendary'
  }
  /* {
    name: "Robe of Useful Items",
    price: 5,
    rarity: "Uncommon"
  } */
]

export const combatItems = [
  {
    name: 'Vicious Weapon',
    price: 350,
    rarity: 'Rare'
  },
  {
    name: 'Adamantine Armor',
    price: 500,
    rarity: 'Uncommon',
    description: 'Add this cost to the regular price of an armor of your choice.'
  },
  {
    name: 'Mithral Armor',
    price: 800,
    rarity: 'Uncommon'
  },
  {
    name: '+1 Weapon',
    price: 1000,
    rarity: 'Uncommon'
  },
  {
    name: 'Sword of Life-Stealing',
    price: 1000,
    rarity: 'Rare'
  },
  {
    name: 'Ioun Stone Protection',
    price: 1200,
    rarity: 'Rare'
  },
  {
    name: 'Wand of the War Mage +1',
    price: 1200,
    rarity: 'Uncommon'
  },
  {
    name: 'Bracers of Archery',
    price: 1500,
    rarity: 'Uncommon'
  },
  {
    name: 'Circlet of Blasting',
    price: 1500,
    rarity: 'Uncommon'
  },
  {
    name: 'Javelin of Lightning',
    price: 1500,
    rarity: 'Uncommon'
  },
  {
    name: 'Prayer Bead - Smiting',
    price: 1500,
    rarity: 'Rare'
  },
  {
    name: 'Wind Fan',
    price: 1500,
    rarity: 'Uncommon'
  },
  {
    name: 'Sword of Sharpness',
    price: 1700,
    rarity: 'Rare'
  },
  {
    name: 'Staff of the Adder',
    price: 1800,
    rarity: 'Uncommon'
  },
  {
    name: 'Dancing Sword',
    price: 2000,
    rarity: 'Very Rare'
  },
  {
    name: 'Glamoured Studded Leather',
    price: 2000,
    rarity: 'Rare'
  },
  {
    name: 'Pipes of the Sewers',
    price: 2000,
    rarity: 'Uncommon'
  },
  {
    name: 'Prayer Bead - Bless',
    price: 2000,
    rarity: 'Rare'
  },
  {
    name: 'Saddle of the Cavalier',
    price: 2000,
    rarity: 'Uncommon'
  },
  {
    name: 'Sword of Wounding',
    price: 2000,
    rarity: 'Rare'
  },
  {
    name: 'Frost Brand',
    price: 2200,
    rarity: 'Very Rare'
  },
  {
    name: 'Dagger of Venom',
    price: 2500,
    rarity: 'Rare'
  },
  {
    name: 'Gloves of Missile Snaring',
    price: 3000,
    rarity: 'Uncommon'
  },
  {
    name: 'Ioun Stone Agility',
    price: 3000,
    rarity: 'Very Rare'
  },
  {
    name: 'Ioun Stone Fortitude',
    price: 3000,
    rarity: 'Very Rare'
  },
  {
    name: 'Ioun Stone Insight',
    price: 3000,
    rarity: 'Very Rare'
  },
  {
    name: 'Ioun Stone Intellect',
    price: 3000,
    rarity: 'Very Rare'
  },
  {
    name: 'Ioun Stone Leadership',
    price: 3000,
    rarity: 'Very Rare'
  },
  {
    name: 'Ioun Stone Strength',
    price: 3000,
    rarity: 'Very Rare'
  },
  {
    name: 'Staff of Withering',
    price: 3000,
    rarity: 'Rare'
  },
  {
    name: 'Cloak of Protection',
    price: 3500,
    rarity: 'Uncommon'
  },
  {
    name: 'Oathbow',
    price: 3500,
    rarity: 'Very Rare'
  },
  {
    name: 'Ring of Protection',
    price: 3500,
    rarity: 'Rare'
  },
  {
    name: '+2 Weapon',
    price: 4000,
    rarity: 'Rare'
  },
  {
    name: 'Boots of Speed',
    price: 4000,
    rarity: 'Rare'
  },
  {
    name: 'Dragon Scale Mail',
    price: 4000,
    rarity: 'Very Rare'
  },
  {
    name: 'Elven Chain',
    price: 4000,
    rarity: 'Rare'
  },
  {
    name: 'Ioun Stone Regeneration',
    price: 4000,
    rarity: 'Legendary'
  },
  {
    name: 'Iron Bands of Bilarro',
    price: 4000,
    rarity: 'Rare'
  },
  {
    name: 'Prayer Bead - Curing',
    price: 4000,
    rarity: 'Rare'
  },
  {
    name: 'Rope of Entanglement',
    price: 4000,
    rarity: 'Rare'
  },
  {
    name: 'Wand of Enemy Detection',
    price: 4000,
    rarity: 'Rare'
  },
  {
    name: 'Luckstone',
    price: 4200,
    rarity: 'Uncommon'
  },
  {
    name: 'Wand of the War Mage +2',
    price: 4800,
    rarity: 'Rare'
  },
  {
    name: 'Flame Tongue',
    price: 5000,
    rarity: 'Rare'
  },
  {
    name: 'Periapt of Wound Closure',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Ring of Evasion',
    price: 5000,
    rarity: 'Rare'
  },
  {
    name: 'Ring of the Ram',
    price: 5000,
    rarity: 'Rare'
  },
  {
    name: 'Tentacle Rod',
    price: 5000,
    rarity: 'Rare'
  },
  {
    name: 'Animated Shield',
    price: 6000,
    rarity: 'Very Rare'
  },
  {
    name: 'Armor of Resistance',
    price: 6000,
    rarity: 'Rare'
  },
  {
    name: 'Arrow-Catching Shield',
    price: 6000,
    rarity: 'Rare'
  },
  {
    name: 'Belt of Dwarvenkind',
    price: 6000,
    rarity: 'Rare'
  },
  {
    name: 'Bracers of Defense',
    price: 6000,
    rarity: 'Rare'
  },
  {
    name: 'Ioun Stone Reserve',
    price: 6000,
    rarity: 'Rare'
  },
  {
    name: 'Pearl of Power',
    price: 6000,
    rarity: 'Uncommon'
  },
  {
    name: 'Pipes of Haunting',
    price: 6000,
    rarity: 'Uncommon'
  },
  {
    name: 'Ring of Resistance',
    price: 6000,
    rarity: 'Rare'
  },
  {
    name: 'Robe of Scintillating Colors',
    price: 6000,
    rarity: 'Very Rare'
  },
  {
    name: 'Scimitar of Speed',
    price: 6000,
    rarity: 'Very Rare'
  },
  {
    name: 'Shield of Missile Attraction',
    price: 6000,
    rarity: 'Rare'
  },
  {
    name: 'Giant Slayer',
    price: 7000,
    rarity: 'Rare'
  },
  {
    name: 'Mace of Smiting',
    price: 7000,
    rarity: 'Rare'
  },
  {
    name: 'Brooch of Shielding',
    price: 7500,
    rarity: 'Uncommon'
  },
  {
    name: 'Amulet of Health',
    price: 8000,
    rarity: 'Rare'
  },
  {
    name: 'Dragon Slayer',
    price: 8000,
    rarity: 'Rare'
  },
  {
    name: 'Gauntlets of Ogre Power',
    price: 8000,
    rarity: 'Uncommon'
  },
  {
    name: 'Headband of Intellect',
    price: 8000,
    rarity: 'Uncommon'
  },
  {
    name: 'Mace of Disruption',
    price: 8000,
    rarity: 'Rare'
  },
  {
    name: 'Mace of Terror',
    price: 8000,
    rarity: 'Rare'
  },
  {
    name: 'Nine Lives Stealer (Fully Charged)',
    price: 8000,
    rarity: 'Very Rare'
  },
  {
    name: 'Wand of Magic Missiles',
    price: 8000,
    rarity: 'Uncommon'
  },
  {
    name: 'Wand of Web',
    price: 8000,
    rarity: 'Uncommon'
  },
  {
    name: 'Staff of Thunder and Lightning',
    price: 10000,
    rarity: 'Very Rare'
  },
  {
    name: 'Wand of Binding',
    price: 10000,
    rarity: 'Rare'
  },
  {
    name: 'Wand of Fear',
    price: 10000,
    rarity: 'Rare'
  },
  {
    name: 'Ioun Stone Awareness',
    price: 12000,
    rarity: 'Rare'
  },
  {
    name: 'Rod of the Pact Keeper +1',
    price: 12000,
    rarity: 'Rare'
  },
  {
    name: 'Staff of Charming',
    price: 12000,
    rarity: 'Rare'
  },
  {
    name: 'Sunblade',
    price: 12000,
    rarity: 'Rare'
  },
  {
    name: 'Staff of Healing',
    price: 13000,
    rarity: 'Rare'
  },
  {
    name: 'Ring of Shooting Stars',
    price: 14000,
    rarity: 'Very Rare'
  },
  {
    name: 'Ioun Stone Mastery',
    price: 15000,
    rarity: 'Legendary'
  },
  {
    name: '+3 Weapon',
    price: 16000,
    rarity: 'Very Rare'
  },
  {
    name: 'Hammer of Thunderbolts',
    price: 16000,
    rarity: 'Legendary'
  },
  {
    name: 'Rod of the Pact Keeper +2',
    price: 16000,
    rarity: 'Rare'
  },
  {
    name: 'Staff of Fire',
    price: 16000,
    rarity: 'Very Rare'
  },
  {
    name: 'Staff of Swarming Insects',
    price: 16000,
    rarity: 'Rare'
  },
  {
    name: 'Wand of Paralysis',
    price: 16000,
    rarity: 'Rare'
  },
  {
    name: 'Ring of Fire Elemental Command',
    price: 17000,
    rarity: 'Legendary'
  },
  {
    name: 'Dwarven Thrower',
    price: 18000,
    rarity: 'Very Rare'
  },
  {
    name: 'Wand of the War Mage +3',
    price: 19200,
    rarity: 'Very Rare'
  },
  {
    name: 'Efreeti Chain',
    price: 20000,
    rarity: 'Legendary'
  },
  {
    name: 'Ring of Free Action',
    price: 20000,
    rarity: 'Rare'
  },
  {
    name: 'Sentinel Shield',
    price: 20000,
    rarity: 'Uncommon'
  },
  {
    name: 'Staff of Striking',
    price: 21000,
    rarity: 'Very Rare'
  },
  {
    name: 'Ring of Spell Storing',
    price: 24000,
    rarity: 'Rare'
  },
  {
    name: 'Vorpal Sword',
    price: 24000,
    rarity: 'Legendary'
  },
  {
    name: 'Ring of Water Elemental Command',
    price: 25000,
    rarity: 'Legendary'
  },
  {
    name: 'Rod of Alertness',
    price: 25000,
    rarity: 'Very Rare'
  },
  {
    name: 'Staff of Frost',
    price: 26000,
    rarity: 'Very Rare'
  },
  {
    name: 'Instrument of the Bards - Fochulan Bandlore',
    price: 26500,
    rarity: 'Uncommon'
  },
  {
    name: 'Instrument of the Bards - Mac-Fuirmidh Cittern',
    price: 27000,
    rarity: 'Uncommon'
  },
  {
    name: 'Rod of Lordly Might',
    price: 28000,
    rarity: 'Legendary'
  },
  {
    name: 'Rod of the Pact Keeper +3',
    price: 28000,
    rarity: 'Very Rare'
  },
  {
    name: 'Instrument of the Bards - Doss Lute',
    price: 28500,
    rarity: 'Uncommon'
  },
  {
    name: 'Instrument of the Bards - Canaith Mandolin',
    price: 30000,
    rarity: 'Rare'
  },
  {
    name: 'Mantle of Spell Resistance',
    price: 30000,
    rarity: 'Rare'
  },
  {
    name: 'Ring of Spell Turning',
    price: 30000,
    rarity: 'Legendary'
  },
  {
    name: 'Prayer Bead - Favor',
    price: 32000,
    rarity: 'Rare'
  },
  {
    name: 'Wand of Fireballs',
    price: 32000,
    rarity: 'Rare'
  },
  {
    name: 'Wand of Lightning Bolts',
    price: 32000,
    rarity: 'Rare'
  },
  {
    name: 'Wand of Polymorph',
    price: 32000,
    rarity: 'Very Rare'
  },
  {
    name: 'Instrument of the Bards - Cli Lyre',
    price: 35000,
    rarity: 'Rare'
  },
  {
    name: 'Scarab of Protection',
    price: 36000,
    rarity: 'Legendary'
  },
  {
    name: 'Sword of Answering',
    price: 36000,
    rarity: 'Legendary'
  },
  {
    name: 'Staff of the Woodlands',
    price: 44000,
    rarity: 'Rare'
  },
  {
    name: 'Spellguard Shield',
    price: 50000,
    rarity: 'Very Rare'
  },
  {
    name: 'Cloak of Displacement',
    price: 60000,
    rarity: 'Rare'
  },
  {
    name: 'Robe of Stars',
    price: 60000,
    rarity: 'Very Rare'
  },
  {
    name: 'Weapon of Warning',
    price: 60000,
    rarity: 'Uncommon'
  },
  {
    name: 'Prayer Bead - Wind Walking',
    price: 96000,
    rarity: 'Rare'
  },
  {
    name: 'Instrument of the Bards - Anstruth Harp',
    price: 109000,
    rarity: 'Very Rare'
  },
  {
    name: 'Instrument of the Bards - Ollamh Harp',
    price: 125000,
    rarity: 'Legendary'
  },
  {
    name: 'Prayer Bead - Summons',
    price: 128000,
    rarity: 'Rare'
  },
  {
    name: 'Holy Avenger',
    price: 165000,
    rarity: 'Legendary'
  }
]

export const nonCombatItems = [
  {
    name: 'Helm of Comprehending Languages',
    price: 500,
    rarity: 'Uncommon'
  },
  {
    name: 'Driftglobe',
    price: 750,
    rarity: 'Uncommon'
  },
  {
    name: 'Trident of Fish Command',
    price: 800,
    rarity: 'Uncommon'
  },
  {
    name: 'Cap of Water Breathing',
    price: 1000,
    rarity: 'Uncommon'
  },
  {
    name: 'Eversmoking Bottle',
    price: 1000,
    rarity: 'Uncommon'
  },
  {
    name: 'Quiver of Ehlonna',
    price: 1000,
    rarity: 'Uncommon'
  },
  {
    name: 'Ioun Stone Sustenance',
    price: 1000,
    rarity: 'Rare'
  },
  {
    name: 'Ring of Warmth',
    price: 1000,
    rarity: 'Uncommon'
  },
  {
    name: 'Goggles of Night',
    price: 1500,
    rarity: 'Uncommon'
  },
  {
    name: 'Horseshoes of the Zephyr',
    price: 1500,
    rarity: 'Very Rare'
  },
  {
    name: "Mariner's Armor",
    price: 1500,
    rarity: 'Uncommon'
  },
  {
    name: 'Necklace of Adaptation',
    price: 1500,
    rarity: 'Uncommon'
  },
  {
    name: 'Ring of Water Walking',
    price: 1500,
    rarity: 'Uncommon'
  },
  {
    name: 'Wand of Magic Detection',
    price: 1500,
    rarity: 'Uncommon'
  },
  {
    name: 'Wand of Secrets',
    price: 1500,
    rarity: 'Uncommon'
  },
  {
    name: 'Gloves of Swimming and Climbing',
    price: 2000,
    rarity: 'Uncommon'
  },
  {
    name: "Heward's Handy Haversack",
    price: 2000,
    rarity: 'Rare'
  },
  {
    name: 'Rope of Climbing',
    price: 2000,
    rarity: 'Uncommon'
  },
  {
    name: 'Ring of Feather Falling',
    price: 2000,
    rarity: 'Rare'
  },
  {
    name: 'Boots of Elvenkind',
    price: 2500,
    rarity: 'Uncommon'
  },
  {
    name: 'Eyes of Minute Seeing',
    price: 2500,
    rarity: 'Uncommon'
  },
  {
    name: 'Eyes of the Eagle',
    price: 2500,
    rarity: 'Uncommon'
  },
  {
    name: 'Ring of Jumping',
    price: 2500,
    rarity: 'Uncommon'
  },
  {
    name: 'Dimensional Shackles',
    price: 3000,
    rarity: 'Rare'
  },
  {
    name: 'Eyes of Charming',
    price: 3000,
    rarity: 'Uncommon'
  },
  {
    name: 'Medallion of Thoughts',
    price: 3000,
    rarity: 'Uncommon'
  },
  {
    name: 'Ring of Swimming',
    price: 3000,
    rarity: 'Uncommon'
  },
  {
    name: 'Bag of Holding',
    price: 4000,
    rarity: 'Uncommon'
  },
  {
    name: 'Boots of Levitation',
    price: 4000,
    rarity: 'Rare'
  },
  {
    name: 'Ring of Animal Influence',
    price: 4000,
    rarity: 'Rare'
  },
  {
    name: 'Boots of Striding and Springing',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Cloak of Arachnida',
    price: 5000,
    rarity: 'Very Rare'
  },
  {
    name: 'Cloak of Elvenkind',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Gloves of Thievery',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Hat of Disguise',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Horseshoes of Speed',
    price: 5000,
    rarity: 'Rare'
  },
  {
    name: 'Immovable Rod',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Lantern of Revealing',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Periapt of Health',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Periapt of Proof Against Poison',
    price: 5000,
    rarity: 'Rare'
  },
  {
    name: 'Slippers of Spider Climbing',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Cloak of the Bat',
    price: 6000,
    rarity: 'Rare'
  },
  {
    name: 'Cloak of the Manta Ray',
    price: 6000,
    rarity: 'Uncommon'
  },
  {
    name: 'Ring of X-Ray Vision',
    price: 6000,
    rarity: 'Rare'
  },
  {
    name: 'Cape of the Mountebank',
    price: 8000,
    rarity: 'Rare'
  },
  {
    name: 'Portable Hole',
    price: 8000,
    rarity: 'Rare'
  },
  {
    name: 'Apparatus of Kwalish',
    price: 10000,
    rarity: 'Legendary'
  },
  {
    name: 'Boots of the Winterlands',
    price: 10000,
    rarity: 'Uncommon'
  },
  {
    name: 'Folding Boat',
    price: 10000,
    rarity: 'Rare'
  },
  {
    name: 'Ring of Invisibility',
    price: 10000,
    rarity: 'Legendary'
  },
  {
    name: 'Helm of Telepathy',
    price: 12000,
    rarity: 'Uncommon'
  },
  {
    name: 'Cube of Force',
    price: 16000,
    rarity: 'Rare'
  },
  {
    name: 'Ring of Mind Shielding',
    price: 16000,
    rarity: 'Uncommon'
  },
  {
    name: 'Rod of Rulership',
    price: 16000,
    rarity: 'Rare'
  },
  {
    name: 'Mirror of Life Trapping',
    price: 18000,
    rarity: 'Very Rare'
  },
  {
    name: 'Amulet of Proof Against Detection and Location',
    price: 20000,
    rarity: 'Uncommon'
  },
  {
    name: 'Robe of Eyes',
    price: 30000,
    rarity: 'Rare'
  },
  {
    name: 'Gem of Seeing',
    price: 32000,
    rarity: 'Rare'
  },
  {
    name: 'Plate Armor of Etherealness',
    price: 48000,
    rarity: 'Legendary'
  }
]

const summoningItems = [
  {
    name: 'Ivory Goat (Travail)',
    price: 400,
    rarity: 'Rare',
    link: 'figurine-of-wondrous-power-ivory-goats',
    toolsLink: 'figurine%20of%20wondrous%20power%2c%20ivory%20goats'
  },
  {
    name: 'Golden Lion (ea)',
    price: 600,
    rarity: 'Rare',
    link: 'figurine-of-wondrous-power-golden-lions',
    toolsLink: 'figurine of wondrous%20power%2c%20golden%20lions'
  },
  {
    name: 'Ivory Goat (Traveling)',
    price: 1000,
    rarity: 'Rare',
    link: 'figurine-of-wondrous-power-ivory-goats'
  },
  {
    name: 'Staff of the Python',
    price: 2000,
    rarity: 'Uncommon'
  },
  {
    name: 'Onyx Dog',
    price: 3000,
    rarity: 'Rare'
  },
  {
    name: 'Silver Raven',
    price: 5000,
    rarity: 'Uncommon'
  },
  {
    name: 'Silver Horn of Valhalla',
    price: 5600,
    rarity: 'Rare'
  },
  {
    name: 'Marble Elephant',
    price: 6000,
    rarity: 'Rare'
  },
  {
    name: 'Bowl of Commanding Water Elementals',
    price: 8000,
    rarity: 'Rare'
  },
  {
    name: 'Brazier of Commanding Fire Elementals',
    price: 8000,
    rarity: 'Rare'
  },
  {
    name: 'Censer of Controlling Air Elementals',
    price: 8000,
    rarity: 'Rare'
  },
  {
    name: 'Stone of Controlling Earth Elementals',
    price: 8000,
    rarity: 'Rare'
  },
  {
    name: 'Brass Horn of Valhalla',
    price: 8400,
    rarity: 'Rare'
  },
  {
    name: 'Bronze Horn of Valhalla',
    price: 11200,
    rarity: 'Very Rare'
  },
  {
    name: 'Iron Horn of Valhalla',
    price: 14000,
    rarity: 'Legendary'
  },
  {
    name: 'Ivory Goat (Terror)',
    price: 20000,
    rarity: 'Rare',
    link: 'figurine-of-wondrous-power-ivory-goats'
  }
]

const allItems = [...consumableItems, ...combatItems, ...nonCombatItems, ...summoningItems]

const rarityScore = (rarity) => {
  switch (rarity) {
  case 'Common':
    return 1
  case 'Uncommon':
    return 2
  case 'Rare':
    return 3
  case 'Very Rare':
    return 4
  case 'Legendary':
    return 5
  default:
    return 0
  }
}

export const partyLevelWealth = [
  0,
  0,
  94,
  188,
  376,
  658,
  2930,
  5404,
  8610,
  12019,
  16563,
  21108,
  30161,
  39214,
  57320,
  75427,
  102586,
  129745,
  214204,
  383123,
  552042,
  805420
]

export const randomItem = (randomFunction, filterFunction) => {
  const filteredItems = allItems.filter(filterFunction)
  const randomNumber = randomFunction()
  const randomItem = filteredItems[Math.floor(randomNumber * filteredItems.length)]

  return randomItem
}

export const randomItems = (randomFunction, filterFunction, count) => {
  return [...Array(count)].map(() => randomItem(randomFunction, filterFunction))
}

export const randomItemByPriceAndRarity = (randomFunction, priceLimit, rarityLimit) => {
  return randomItem(randomFunction, (item) => item.price <= priceLimit && rarityScore(item.rarity) <= rarityScore(rarityLimit))
}

export const randomItemsByPriceAndRarity = (randomFunction, priceLimit, rarityLimit, count) => {
  return [...Array(count)].map(() => randomItemByPriceAndRarity(randomFunction, priceLimit, rarityLimit))
}

export const nonLegendaryItemsBySeed = (seed, count, partyLevel) => {
  const sfc32 = rng(seed)
  return randomItemsByPriceAndRarity(sfc32, partyLevelWealth[partyLevel] * 3, 'Very Rare', count)
}

export const nonLegendaryItem = (seedFunc, partyLevel) => {
  return randomItemsByPriceAndRarity(seedFunc, partyLevelWealth[partyLevel], 'Very Rare', 1)
}
