import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Directory } from './pages/Directory'
import { CharacterPage, ErrorPage, FactionPage, LocationPage, LongTestPage, DeityPage, SearchPage, ShipPage, SublocationPage, AlignmentTestPage } from './pages/DataDisplay'
import { Root } from './pages/Root'
import { HistoryPage } from './pages/HistoryPage'
import { GeneratorPage } from './pages/NameGenerator'
import { RulesPage } from './pages/RulesPage'
import { Export } from './pages/Export'
import { Shop } from './pages/Shop'
import { ItemGenerator } from './pages/ItemGenerator'
import { PartyWealth } from './pages/PartyWealth'

/**
 *
 * @returns {JSX.Element} app
 */
export const App = () => {
  const router = createBrowserRouter(
    [
      {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: '/',
            element: <SearchPage />
          },
          {
            path: 'characters',
            element: <Directory dataKey="characters" />
          },
          {
            path: 'characters/:characterId',
            element: <CharacterPage />
          },
          {
            path: 'deities',
            element: <Directory dataKey="deities" square />
          },
          {
            path: 'deities/:deityId',
            element: <DeityPage />
          },
          {
            path: 'history',
            element: <HistoryPage />
          },
          {
            path: 'locations',
            element: <Directory dataKey="locations" />
          },
          {
            path: 'locations/:locationId',
            element: <LocationPage />
          },
          {
            path: 'locations/:locationId/:sublocationId',
            element: <SublocationPage />
          },
          {
            path: 'factions',
            element: <Directory dataKey="factions" />
          },
          {
            path: 'factions/:factionId',
            element: <FactionPage />
          },
          {
            path: 'ships',
            element: <Directory dataKey="ships" />
          },
          {
            path: 'ships/:shipId',
            element: <ShipPage />
          },
          {
            path: 'generate-names',
            element: <GeneratorPage />
          },
          {
            path: 'generate-items',
            element: <ItemGenerator />
          },
          {
            path: 'party-wealth',
            element: <PartyWealth />
          },
          {
            path: 'alignment',
            element: <AlignmentTestPage />
          },
          {
            path: 'rules',
            element: <RulesPage />
          },
          {
            path: 'shop',
            element: <Shop />
          },
          {
            path: 'shop/:day',
            element: <Shop />
          },
          {
            path: 'long',
            element: <LongTestPage />
          },
          {
            path: 'export',
            element: <Export />
          }
        ]
      }
    ]
  )

  return (
    <RouterProvider router={router} />
  )
}
