import { Tag } from '@zendeskgarden/react-tags'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

/**
 * Renders a status tag based on the provided status prop.
 * @param {Object} props - The component props.
 * @param {string} props.status - The status to display.
 *
 * @returns {JSX.Element} - The rendered status tag.
 */
export const Status = ({ status }) => {
  const { palette } = useContext(ThemeContext)

  let statusColor = palette.grey[800]
  let statusLabel = 'Unknown'

  if (status === 'fine') {
    statusColor = palette.green[100]
    statusLabel = 'Fine'
  } else if (status === 'dead') {
    statusColor = palette.red[800]
    statusLabel = 'Dead'
  }

  return (
    <Tag hue={statusColor}>
      {statusLabel}
    </Tag>
  )
}
