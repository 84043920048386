import React from 'react'
import { monthToExandrianMonth } from './ExandrianDateUtils'
import { FullImage as StyledImage } from './components/SizedImage'

import edaOweland from './images/Eda_Oweland.png'
import krebShenker from './images/Kreb_Shenker.png'
import captainXendros from './images/Captain_Xendros.png'
import gellanPrimewater from './images/Gellan_Primewater.png'
import oceanus from './images/Oceanus.png'
import raggedyAnne from './images/Raggedy_Anne.png'
import jim from './images/Jim.png'
import terese from './images/Terese.png'
import kendrick from './images/Kendrick.png'
import beatriceGuldeer from './images/Beatrice_Guldeer.png'
import sigurdSnakeEyes from './images/Sigurd_Snake_Eyes.png'
import billiam from './images/Billiam.png'
import keledek from './images/Keledek.png'
import boy from './images/Boy.png'
import angelica from './images/Angelica.png'
import trist from './images/Trist.png'
import edgar from './images/Edgar.png'
import sabine from './images/Sabine.png'
import moriarty from './images/Moriarty.png'
import inecaSufocan from './images/Ineca_Sufocan.png'
import thereauxGuldeer from './images/Thereaux_Guldeer.png'
import kraddokStonehorn from './images/Kraddok_Stonehorn.png'
import rachal from './images/Rachal.png'
import projesh from './images/Projesh.png'

import avandra from './images/deities/Avandra.png'
import bahamut from './images/deities/Bahamut.png'
import corellon from './images/deities/Corellon.png'
import kord from './images/deities/Kord.png'
import lolth from './images/deities/Lolth.png'
import melora from './images/deities/Melora.png'
import moradin from './images/deities/Moradin.png'

const EDA_OWELAND = '[Eda Oweland](/characters/0)'
const KREB_SHENKER = '[Kreb Shenker](/characters/1)'
const CAPTAIN_XENDROS = '[Captain Xendros](/characters/2)'
const GELLAN_PRIMEWATER = '[Gellan Primewater](/characters/3)'
const OCEANUS = '[Oceanus](/characters/4)'
const RAGGEDY_ANNE = '[Raggedy Anne](/characters/5)'
const JIM = '[Jim](/characters/6)'
const TERESE = '[Terese](/characters/7)'
const KENDRICK = '[Kendrick](/characters/8)'
const BEATRICE_GULDEER = '[Beatrice Guldeer](/characters/9)'
const SIGURD_SNAKE_EYES = '[Sigurd Snake Eyes](/characters/10)'
const BILLIAM = '[Billiam](/characters/11)'
const VIGR_THRASS = '[Vigr Thrass](/characters/12)'
const THEREAUX_GULDEER = '[Thereaux Guldeer](/characters/13)'
const INECA_SUFOCAN = '[Ineca Sufocan](/characters/14)'
const KELEDEK = '[Keledek](/characters/15)'
const BOY = '[Boy](/characters/16)'
const ANGELICA = '[Angelica](/characters/17)'
const TRIST = '[Trist](/characters/18)'
const EDGAR = '[Edgar](/characters/19)'
const SABINE = '[Sabine](/characters/20)'
const MORIARTY = '[Moriarty](/characters/21)'
const KRADDOK_STONEHORN = '[Kraddok Stonehorn](/characters/22)'
const RACHAL = '[Rachal](/characters/23)'
const PROJESH = '[Projesh](/characters/24)'

const SALTMARSH = '[Saltmarsh](/locations/0)'
const SALTMARSH_TOWN_COUNCIL = '[Saltmarsh Town Council](/locations/0/0)'
const THE_EMPTY_NET = '[The Empty Net](/locations/0/1)'
const XENDROS_TRADING = '[Xendros Trading](/locations/0/2)'
const HAUNTED_HOUSE = '[Haunted House](/locations/0/3)'
const KELEDEKS_TOWER = '[Keledek\'s Tower](/locations/0/4)'

const PLUMAGE_PORT = '[Plumage Port](/locations/1)'
const PALAZZO_DEL_GULDEER = '[Palazzo del Guldeer](/locations/1/0)'
const BILLIAMS_BROUHAHA = '[Billiam\'s Brouhaha](/locations/1/1)'
const TRADEWIND_MARKET = '[Tradewind Market](/locations/1/2)'
const SHIPFATHERS = '[Shipfather\'s](/locations/1/3)'
const NO_PLACE_LIKE_HERE = '[No Place Like Here](/locations/1/4)'
const BARRETS_BARRACKS = '[Barret\'s Barracks](/locations/1/5)'
const UPPER_DOCKS = '[Upper Docks](/locations/1/6)'
const SHRINE_OF_THE_WAVEMOTHER = '[Shrine of the Wavemother](/locations/1/7)'

const MINTARN = '[Mintarn](/locations/2)'

const ATOLON_DE_AZUCAR = '[Atolon de Azucar](/locations/3)'

const HOOL_MARSHES = '[Hool Marshes](/locations/4)'

const AZURE_SEA = '[Azure Sea](/locations/5)'

const DIMMSBURG = '[Dimmsburg](/locations/6)'
const TEMPLE_OF_AELIA = '[Temple of Aelia](/locations/6/0)'
const EDGARS_CURIO_EMPORIUM = '[Edgars Curio Emporium](/locations/6/1)'
const THE_DOCKS = '[The Docks](/locations/6/2)'

const THE_SEA_GHOST = '[The Sea Ghost](/locations/7)'
const THE_JADED_LADY = '[The Jaded Lady](/locations/8)'
const THE_GNASHER = '[The Gnasher](/locations/9)'
const THE_PALE_PROW = '[The Pale Prow](/locations/10)'

const MELORA = '[Melora](/deities/0)'

/**
 *
 * @type {object}
 * @property {object[]} events
 * @property {string} events[].header
 * @property {string} events[].content
 * @property {object[]} characters
 * @property {string} characters[].name
 * @property {number} characters[].sentiment
 * @property {string} characters[].species
 * @property {object} characters[].faction
 * @property {string} characters[].faction.name
 * @property {string} characters[].faction.link
 * @property {JSX.Element} characters[].image
 * @property {string} characters[].description
 * @property {object[]} locations
 * @property {string} locations[].name
 * @property {string} locations[].description
 * @property {object[]} locations[].locations
 * @property {string} locations[].locations[].name
 * @property {string} locations[].locations[].description
 * @property {object[]} factions
 * @property {string} factions[].name
 * @property {string} factions[].description
 * @property {object[]} deities
 * @property {string} deities[].name
 * @property {string[]} deities[].aliases
 * @property {string[]} deities[].domains
 * @property {JSX.Element} deities[].image
 * @property {string} deities[].description
 * @property {object[]} ancestries
 * @property {string} ancestries[].name
 * @property {object[]} ancestries[].subtype
 * @property {string} ancestries[].subtype[].name
 * @property {string} ancestries[].subtype[].description
 * @property {object[]} ships
 * @property {string} ships[].name
 * @property {string} ships[].description
 */
export const data = {
  events: [
    {
      header: '14th',
      content: '',
      charactersMet: ['Hagar', 'Arti']
    },
    {
      header: '12th',
      content: '',
      charactersMet: ['Shanks', 'Hogfoot', 'Zar Zar']
    },
    {
      header: '11th',
      content: `The party travels back to ${PLUMAGE_PORT} to meet with ${BEATRICE_GULDEER}. They deliver the bad news and are rewarded for their troubles. The party splits ways for a bit before rejoining and getting drunk.`,
      charactersMet: [RACHAL]
    },
    {
      header: '10th - Evening',
      content: `The party has slain ${INECA_SUFOCAN}, and found ${THEREAUX_GULDEER} dead.`
    },
    {
      header: '10th',
      content: `The party follows the compass which takes them to ${DIMMSBURG}. They meet ${TRIST} at the ${TEMPLE_OF_AELIA}.`,
      charactersMet: [MORIARTY, EDGAR, TRIST]
    },
    {
      header: '9th',
      content: `The party travels back to Salthmarsh to complete their quest for ${KREB_SHENKER}. Afterwards, they travel to ${MINTARN} and defeat and capture Angelica.`,
      charactersMet: [BOY, KELEDEK, ANGELICA, PROJESH]
    },
    {
      header: '8th',
      content: `The party travels to ${PLUMAGE_PORT} to evade bounty hunters. They meet ${BEATRICE_GULDEER} and agree to help her.`,
      charactersMet: [BILLIAM, BEATRICE_GULDEER]
    },
    {
      header: '7th',
      content: `The party travels to the ${HOOL_MARSHES} to kill an Ooze for ${KREB_SHENKER}.`,
      charactersMet: [KREB_SHENKER, CAPTAIN_XENDROS]
    },
    {
      header: '6th',
      content: `The party kills ${SIGURD_SNAKE_EYES}. They take his ship, ${THE_SEA_GHOST} and rename it to ${THE_JADED_LADY}.`,
      charactersMet: [KENDRICK, TERESE, SIGURD_SNAKE_EYES, RAGGEDY_ANNE, JIM, OCEANUS]
    },
    {
      header: '5th',
      content: `The party travels to the ${HAUNTED_HOUSE} to clear the house of smugglers for ${EDA_OWELAND}.`,
      charactersMet: [EDA_OWELAND]
    },
    {
      header: `${monthToExandrianMonth(8)} - 856PD`,
      content: `Our story begins in the small port town of ${SALTMARSH}.`
    }
  ],
  characters: [
    {
      name: 'Eda Oweland',
      sentiment: 1,
      species: 'Human - Keolish',
      faction: {
        name: 'Traditionalists',
        link: '/factions/0'
      },
      work: SALTMARSH_TOWN_COUNCIL,
      image: <StyledImage src={edaOweland} alt='Eda Oweland' />,
      description: "Eda Oweland defies the passage of time with her youthful appearance. Her captivating hazel eyes seem to hold a depth of life experiences. She sports medium-length bobbed hair that elegantly frames her face, with subtle blonde accents that add warmth to her look. Eda's attire typically leans towards smartness, often featuring a white dress shirt paired with a canvas gray vest, which imparts sophistication to her enduring allure."
    },
    {
      name: 'Kreb Shenker',
      sentiment: 2,
      species: 'Human - Keolish',
      faction: {
        name: 'Traditionalists',
        link: '/factions/0'
      },
      work: THE_EMPTY_NET,
      image: <StyledImage src={krebShenker} alt='Kreb Shenker' />,
      description: 'In his tavern, Kreb Shenker stands out as a unique figure. A human with the appearance of a dwarf, his head is crowned by a circle of gray hair, encircling a noticeable bald patch, a testament to the passage of time and the wisdom that accompanies it. Despite his small stature, his powerful arms tell the story of years spent laboring behind the bar. His eyes are a dark blue, and his face is pocketed with scars, a reminder of his days as a sailor.'
    },
    {
      name: 'Captain Xendros',
      sentiment: 1,
      species: 'Tiefling - Asmodeus',
      faction: {
        name: 'Tradewind Merchants',
        link: '/factions/1'
      },
      work: XENDROS_TRADING,
      image: <StyledImage src={captainXendros} alt='Captain Xendros' />,
      description: "Captain Xendros is a statuesque woman with crimson skin. She has a braided cascade of long, obsidian hair and horns that curl backward from her temples, with one adorned by a gilded cap. Her confident demeanor shines through her brassy-hued eyes, and tattoos decorate her arms and legs. Completing her striking appearance are tall ebony boots and the flowing silhouette of a pirate captain's cloak."
    },
    {
      name: 'Gellan Primewater',
      sentiment: -5,
      species: 'Human - Keolish',
      faction: {
        name: 'Traditionalists',
        link: '/factions/0'
      },
      work: SALTMARSH_TOWN_COUNCIL,
      image: <StyledImage src={gellanPrimewater} alt='Gellan Primewater' />,
      description: 'Gellan Primewater'
    },
    {
      name: 'Oceanus',
      sentiment: 1,
      species: 'Sea Elf - Azurean',
      faction: {
        name: 'Manaan Tribe',
        link: '/factions/3'
      },
      work: THE_JADED_LADY,
      image: <StyledImage src={oceanus} alt='Oceanus' />,
      description: 'What Oceanus lacks in raw strength, he makes up for in speed and agility. His skin is a deep blue, and his hair is a dark green. He has a propensity for borrowing books and returning them before they are missed.'
    },
    {
      name: 'Raggedy Anne',
      sentiment: 1,
      species: 'Human - Islese',
      faction: {
        name: 'Jaded Lady Pirates',
        link: '/factions/2'
      },
      work: THE_JADED_LADY,
      image: <StyledImage src={raggedyAnne} alt='Raggedy Anne' />,
      description: `Anne is a no-nonense first mate on the Jaded Lady. She previously served as first mate on ${THE_SEA_GHOST} for ${SIGURD_SNAKE_EYES}. But her alliance with him was tenuous at best. She seems cautious of the party, but other crew remark that she seems happier.`
    },
    {
      name: 'Jim',
      sentiment: 1,
      species: 'Human - Islese',
      description: `Jim is a young man who is often found sleeping in the crow's nest. He claims to be the lookout and will fight to maintain that position. ${RAGGEDY_ANNE} figures it's best to keep him up there.`,
      faction: {
        name: 'Jaded Lady Pirates',
        link: '/factions/2'
      },
      work: THE_JADED_LADY,
      image: <StyledImage src={jim} alt='Jim' />
    },
    {
      name: 'Terese',
      sentiment: 1,
      species: 'Djinni - Winds',
      faction: {
        name: 'Jaded Lady Pirates',
        link: '/factions/2'
      },
      work: THE_JADED_LADY,
      image: <StyledImage src={terese} alt='Terese' />
    },
    {
      name: 'Kendrick',
      sentiment: 1,
      species: 'Dwarf - Mountain',
      faction: {
        name: 'Jaded Lady Pirates',
        link: '/factions/2'
      },
      work: THE_JADED_LADY,
      image: <StyledImage src={kendrick} alt='Kendrick' />
    },
    {
      name: 'Beatrice Guldeer',
      sentiment: 1,
      species: 'Giant - Winds',
      faction: {
        name: 'Traditionalists',
        link: '/factions/0'
      },
      description: `Beatrice Guldeer is a giant who lives in the [Palace de Guldeer](/locations/1/0). She is the wife of ${THEREAUX_GULDEER}, and is the Queen of ${PLUMAGE_PORT}.`,
      work: PALAZZO_DEL_GULDEER,
      image: <StyledImage src={beatriceGuldeer} alt='Beatrice Guldeer' />
    },
    {
      name: 'Sigurd Snake Eyes',
      status: 'dead',
      species: 'Half-Orc - Half-Goliath',
      description: `Sigurd Snake Eyes is a dead pirate who operated in the ${AZURE_SEA}. He was the captain of ${THE_SEA_GHOST}, a ship that would move supplies in and out of ${SALTMARSH} for ${GELLAN_PRIMEWATER}.`,
      faction: {
        name: 'The Sea Princes',
        link: '/factions/4'
      },
      work: THE_SEA_GHOST,
      image: <StyledImage src={sigurdSnakeEyes} alt='Sigurd Snake Eyes' />
    },
    {
      name: 'Billiam',
      sentiment: 1,
      species: 'Orc - Islese',
      faction: {
        name: 'Loyalists',
        link: '/factions/5'
      },
      description: `Billiam is a brewer who operates in ${PLUMAGE_PORT}. He operates a river tour of the city and distills a variety of spirits and ales.`,
      work: BILLIAMS_BROUHAHA,
      image: <StyledImage src={billiam} alt='Billiam' />
    },
    {
      name: 'Vigr Thrass',
      description: 'An unknown Sea Prince who sails in a large metal ship. They claim no territory and offer no quarter.',
      faction: {
        name: 'The Sea Princes',
        link: '/factions/4'
      },
      work: 'Unknown'
    },
    {
      name: 'Thereaux Guldeer',
      description: `The King of ${PLUMAGE_PORT} via marriage to ${BEATRICE_GULDEER}. A pirate captain who operates in the ${AZURE_SEA}.`,
      species: 'Half-Dragon - Half-Dragonborn',
      faction: {
        name: 'The Sea Princes',
        link: '/factions/4'
      },
      work: THE_GNASHER,
      image: <StyledImage src={thereauxGuldeer} alt='Thereaux Guldeer' />,
      status: 'dead'
    },
    {
      name: 'Ineca Sufocan',
      description: 'A Sea Prince and vampire who travels on a ship with no sails.',
      faction: {
        name: 'The Sea Princes',
        link: '/factions/4'
      },
      work: THE_PALE_PROW,
      image: <StyledImage src={inecaSufocan} alt='Ineca Sufocan' />,
      species: 'Vampire - Half-Elf',
      status: 'dead'
    },
    {
      name: 'Keledek the Unspoken',
      description: `An eccentric wizard distrusted by the locals of ${SALTMARSH}. He lives in a tower on the outskirts of town where he offers his services as a wizard.`,
      species: 'Wizard - Of the forest',
      faction: {
        name: 'Traditionalists',
        link: '/factions/0'
      },
      work: KELEDEKS_TOWER,
      sentiment: 0,
      image: <StyledImage src={keledek} alt='Keledek' />
    },
    {
      name: 'Boy',
      description: `A young boy of about 12. He is an 'apprentice' to ${KELEDEK}. He is often seen around town running errands for him.`,
      species: 'Human - Keolish',
      work: KELEDEKS_TOWER,
      sentiment: 5,
      image: <StyledImage src={boy} alt="Boy" />
    },
    {
      name: 'Angelica',
      description: `One of ${INECA_SUFOCAN}'s stewardesses. She is a vampire who goes from island to island, draining the life from the inhabitants as a tax for being in Ineca's realm`,
      species: 'Vampire - Human',
      faction: {
        name: 'Crimson Court',
        link: '/factions/6'
      },
      sentiment: -5,
      work: THE_PALE_PROW,
      image: <StyledImage src={angelica} alt='Angelica' />
    },
    {
      name: 'Trist',
      description: 'A cleric at the Temple of Aelia on Dimmsburg. She was a divination wizard who had to shift studies when her parents passed. She inherited the temple and has been running it ever since.',
      species: 'Half-Elf - Half-Human',
      faction: {
        name: 'Loyalists',
        link: '/factions/5'
      },
      work: TEMPLE_OF_AELIA,
      sentiment: 5,
      image: <StyledImage src={trist} alt='Trist' />
    },
    {
      name: 'Edgar',
      species: 'Tabaxi - Jaguar',
      description: `A young tabaxi who peddles trinkets, curios, and treasure maps in ${DIMMSBURG}.`,
      work: EDGARS_CURIO_EMPORIUM,
      image: <StyledImage src={edgar} alt='Edgar' />,
      sentiment: 1
    },
    {
      name: 'Sabine',
      species: 'Half-Human - Half-Orc',
      status: 'dead',
      faction: {
        name: 'Jaded Lady Pirates',
        link: '/factions/2'
      },
      image: <StyledImage src={sabine} alt='Sabine'/>
    },
    {
      name: 'Moriarty',
      species: 'Half-Human - Half-Dwarf',
      description: '',
      image: <StyledImage src={moriarty} alt='Moriarty' />,
      work: THE_DOCKS,
      sentiment: 0
    },
    {
      name: 'Kraddok Stonehorn',
      species: 'Human - Keolish',
      description: 'A large graying man with a tired, yet determined look to his face. He is a captain in the Keoland Navy.',
      image: <StyledImage src={kraddokStonehorn} alt='Kraddok Stonehorn' />,
      work: 'Keoland Navy',
      sentiment: 0
    },
    {
      name: 'Rachal',
      species: 'Dragonborn - Copper',
      description: '',
      image: <StyledImage src={rachal} alt='Rachal' />,
      work: 'Book Store',
      sentiment: 1
    },
    {
      name: 'Projesh',
      species: 'Dragonborn',
      description: '',
      image: <StyledImage src={projesh} alt='Projesh' />,
      work: '',
      sentiment: 1
    }
  ],
  locations: [
    {
      name: 'Saltmarsh',
      description: `Saltmarsh is a small fishing village located on the coast of the ${AZURE_SEA}. It is a quiet, sleepy town, and its residents are wary of outsiders.`,
      locations: [
        {
          name: 'Saltmarsh Town Council',
          description: 'The Saltmarsh Town Council is the governing body of the town. It is led by Eda Oweland, who is also the town\'s speaker.'
        },
        {
          name: 'The Empty Net',
          description: 'The Empty Net is a dirty, whole in the wall tavern. It is owned by Kreb Shenker, who also works as the tavern\'s bartender.'
        },
        {
          name: 'Xendros Trading',
          description: `Xendros Trading is a large permanent stall set up in the market. It is owned by ${CAPTAIN_XENDROS}.`
        },
        {
          name: 'Haunted House',
          description: 'The Haunted House is a large, abandoned house located on the outskirts of town, facing a cliff towards the sea.'
        },
        {
          name: 'Keledek\'s Tower',
          description: 'Keledek\'s Tower is a short and stout tower located on the outskirts of town. It is rather unimposing from the outside, and could easily be mistaken for a stone silo.'
        }
      ]
    },
    {
      name: 'Plumage Port',
      description: 'A large trading port located just south of minor Keoland. It is the largest port in the region, and is home to a large number of merchants and traders. It is one of the northernmost islands in the Isles of Ichor.',
      locations: [
        {
          name: 'Palazzo del Guldeer',
          description: 'The Palazzo del Guldeer is the home of the Guldeer family, the rulers of the city. It is a large, opulent palace made of alabaster and marble.'
        },
        {
          name: 'Billiam\'s Brouhaha',
          description: 'Billiam\'s Brouhaha is a distillery and brewery. It is owned by Billiam.'
        },
        {
          name: 'Tradewind Market',
          description: 'The Tradewind Market is a large, bustling place. It is home to a large number of merchants and traders.'
        },
        {
          name: 'Shipfather\'s',
          description: 'Shipfather\'s is a shipyard. It is run by a large family of catfolk.'
        },
        {
          name: 'No Place Like Here',
          description: `No Place Like Here is an inn/tavern that sells mostly ${BILLIAM}'s spirits. It is owned by a halfling named Gilly.`
        },
        {
          name: 'Barret\'s Barracks',
          description: 'Barret\'s Barracks is a barracks that hosts the town militia.'
        },
        {
          name: 'Upper Docks',
          description: 'The Upper Docks are a set of well maintained docks. They are located underneath the Palazzo del Guldeer.'
        },
        {
          name: 'Shrine of the Wavemother',
          description: `The Shrine of the Wavemother is a shrine to vcarious gods worshipped in the Isles of Ichor. It is mainly dedicated to ${MELORA}, the goddess of the sea but their are shrines to a number of other gods as well.`
        }
      ]
    },
    {
      name: 'Mintarn',
      description: `Mintarn is a small island located east of ${PLUMAGE_PORT}. It is relatively unpopulated, and is home to a small fishing village.`
    },
    {
      name: 'Atolon de Azucar',
      description: 'Atolon de Azucar is a small island located in the Isles of Ichor. It is home to a small population of sugarcane farmers and sailors.'
    },
    {
      name: 'Hool Marshes',
      description: 'The Hool Marshes are a large swamp located on the coast of Keoland. They are home to a large number of dangerous creatures.'
    },
    {
      name: 'The Azure Sea',
      description: 'The Azure Sea is a large body of water located south of Keoland and envelops the Isles of Ichor.'
    },
    {
      name: 'Dimmsburg',
      description: 'Dimmsburg is a dying town to the far east. It is covered in dense rainforest and has a large number of ruined structures.',
      locations: [
        {
          name: 'Temple of Aelia',
          description: ''
        },
        {
          name: 'Edgars Curio Emporium',
          description: ''
        },
        {
          name: 'The Docks',
          description: ''
        }
      ]
    },
    {
      name: 'The Sea Ghost',
      description: `The Sea Ghost was a small, two-masted ship that was captained by ${SIGURD_SNAKE_EYES}. It was used to smuggle goods in and out of ${SALTMARSH} until one day it was taken by the party and renamed to ${THE_JADED_LADY}.`
    },
    {
      name: 'The Jaded Lady',
      description: `Formerly known as ${THE_SEA_GHOST}, The Jaded Lady is a small, two-masted ship.`
    },
    {
      name: 'The Gnasher',
      description: `A large, three masted ship with a large dragon head on the front. It was captained by ${THEREAUX_GULDEER}.`
    },
    {
      name: 'The Pale Prow',
      description: `A large decrepit ship with no sails. It was captained by ${INECA_SUFOCAN}.`
    }
  ],
  factions: [
    {
      name: 'Traditionalists',
      description: 'The Traditionalists are not a formal organization, but rather a group of like-minded individuals who believe that Keoland should keep their nose out of the business in the South. They are opposed to any military expansion from the crown and are leary of outsiders.'
    },
    {
      name: 'Tradewind Merchants',
      description: `The Tradewind Merchants are a group of merchants who operate across the Isles. They have come to ${SALTMARSH} to take advantage of the town's new port. They are represented by Captain Xendros, a tiefling merchant who has set up shop in the town's market.`
    },
    {
      name: 'Jaded Lady Pirates',
      description: 'Pirates on The Jaded Lady.'
    },
    {
      name: 'Manaan Tribe',
      description: `A tribe of sea elves who live in the ${AZURE_SEA}.`
    },
    {
      name: 'The Sea Princes',
      description: 'A confederacy of pirates who operate in the Isles of Ichor.'
    },
    {
      name: 'Loyalists',
      description: 'The Loyalists are loyal to the monarchy of Keoland and believe that all would benefit from more involvement from the crown.'
    },
    {
      name: 'Crimson Court',
      description: `The Crimson Court is the name of the pirates who follow ${INECA_SUFOCAN}.`
    }
  ],
  deities: [
    {
      name: 'Melora',
      aliases: ['The Wildmother', 'The Wild Mother', 'an-Melzidanye', 'Serataani'],
      domains: ['Life', 'Nature', 'Tempest'],
      alignment: 'TN',
      description: 'Melora is the goddess of the wilderness and the sea. She is worshipped by sailors and those who live off the land. She is better known as the Wildmother in the northern continents.',
      image: <StyledImage src={melora} alt='Melora' />
    },
    {
      name: 'Aelia',
      aliases: [],
      domains: [],
      alignment: '',
      description: `Aelia is an old god, disdained by most people of the ${AZURE_SEA}. It is not known what she is a god of, but local traditions dictate spitting on images of her to bring good fortune.`
    },
    {
      name: 'Kord',
      aliases: ['The Stormlord', 'The Storm Lord'],
      domains: ['Tempest', 'War'],
      alignment: 'CN',
      description: 'Kord is the god of strength, storms, and battle. He is worshipped by warriors and sailors.',
      image: <StyledImage src={kord} alt='Kord' />
    },
    {
      name: 'Lolth',
      aliases: ['The Spider Queen'],
      domains: ['Knowledge', 'Trickery'],
      alignment: 'CE',
      description: 'Lolth is the goddess of the Underdark.',
      image: <StyledImage src={lolth} alt='Lolth' />
    },
    {
      name: 'Avandra',
      aliases: ['The Changebringer', 'The Change Bringer', 'The Changebearer', 'She Who Makes the Path', 'Luck Bringer', 'Lady Luck'],
      domains: ['Moon', 'Nature', 'Trickery'],
      alignment: 'CG',
      image: <StyledImage src={avandra} alt='Avandra' />,
      description: 'Avandra is the goddess of change, luck, and travel. She is worshipped by merchants, adventurers, and travelers.'
    },
    {
      name: 'Bahamut',
      aliases: ['The Platinum Dragon'],
      domains: ['Life', 'Order', 'War'],
      alignment: 'LG',
      image: <StyledImage src={bahamut} alt='Bahamut' />,
      description: 'Bahamut is the god of good aligned dragons and metallic dragons.'
    },
    {
      name: 'Corellon',
      aliases: ['The Archeart', 'The Arch Heart'],
      domains: ['Arcana', 'Nature', 'Light'],
      alignment: 'CG',
      image: <StyledImage src={corellon} alt='Corellon' />,
      description: 'Corellon is the god of arcane magic and the fey. He is worshipped by elves and centaurs.'
    },
    {
      name: 'Erathis',
      aliases: ['The Lawbearer', 'The Law Bearer'],
      domains: ['Knowledge', 'Order'],
      alignment: 'LN'
    },
    {
      name: 'Ioun',
      aliases: ['The Knowing Mistress', 'The Knowing Mentor'],
      domains: ['Arcana', 'Knowledge', 'Twilight'],
      alignment: 'TN'
    },
    {
      name: 'Moradin',
      aliases: ['The Allhammer'],
      domains: ['Forge', 'Knowledge', 'War'],
      alignment: 'LG',
      image: <StyledImage src={moradin} alt='Moradin' />,
      description: 'Moradin is the god of craft, creation, family, and legacy. He is worshipped by crafters, blacksmiths, forgemasters, and artisans.'
    },
    {
      name: 'Pelor',
      aliases: ['The Dawnfather', 'The Dawn Father'],
      domains: ['Life', 'Light', 'Nature'],
      alignment: 'NG'
    },
    {
      name: 'The Raven Queen',
      aliases: ['Matron of Death', 'Matron of Ravens', 'Ebon Queen of Ravens', 'Duskmaven'],
      domains: ['Blood', 'Death', 'Grave', 'Life', 'Twilight'],
      alignment: 'LN'
    },
    {
      name: 'Sarenrae',
      aliases: ['The Everlight', 'The Dawnflower', 'The Redeeming Light'],
      domains: ['Life', 'Light', 'Peace'],
      alignment: 'NG'
    },
    {
      name: 'Sehanine',
      aliases: ['The Moonweaver', 'The Moon Weaver'],
      domains: ['Arcana', 'Moon', 'Nature', 'Trickery', 'Twilight'],
      alignment: 'CG'
    },
    {
      name: 'Asmodeus',
      aliases: ['The Lord of the Nine Hells', 'The Lord of the Hells', 'The Devil Lord', 'The Father of Lies'],
      domains: ['Blood', 'Order', 'Trickery', 'War'],
      alignment: 'LE'
    },
    {
      name: 'Bane',
      aliases: ['The Strife Emperor'],
      domains: ['Forge', 'Order', 'War'],
      alignment: 'LE'
    },
    {
      name: 'Gruumsh',
      aliases: ['The Ruiner'],
      domains: ['Blood', 'Death', 'Tempest', 'War'],
      alignment: 'CE'
    },
    {
      name: 'Tharizdun',
      aliases: ['The Chained Oblivion', 'The Angel of Irons'],
      domains: ['Death', 'Grave', 'Trickery'],
      alignment: 'CE'

    },
    {
      name: 'Tiamat',
      aliases: ['The Scaled Tyrant', 'The Dragon Queen'],
      domains: ['Order', 'Trickery', 'War'],
      alignment: 'LE'
    },
    {
      name: 'Torog',
      aliases: ['The Crawling King'],
      domains: ['Blood', 'Death', 'Trickery'],
      alignment: 'NE'
    },
    {
      name: 'Vecna',
      aliases: ['The Archlich', 'The Lord of the Rotted Tower', 'The Undying King', 'The Whispered One'],
      domains: ['Arcana', 'Death', 'Grave', 'Knowledge'],
      alignment: 'NE'
    },
    {
      name: 'Zehir',
      aliases: ['The Cloaked Serpent'],
      domains: ['Blood', 'Nature', 'Trickery'],
      alignment: 'CE'
    }
  ],
  ancestries: [
    {
      name: 'Human',
      subtype: [
        {
          name: 'Keolish',
          description: 'Keolish humans are humans from Keoland'
        },
        {
          name: 'Islese',
          description: 'Islese humans are humans from the Isles of Ichor'
        }
      ]
    },
    {
      name: 'Dwarf',
      subtype: [
        {
          name: 'Mountain',
          description: 'Mountain dwarves are dwarves from the mountains'
        }
      ]
    }
  ],
  ships: [
    {
      name: 'The Sea Ghost',
      description: `The Sea Ghost was a small, two-masted ship captained by ${SIGURD_SNAKE_EYES}. It was used to smuggle goods in and out of ${SALTMARSH} until one day it was taken by the party and renamed to ${THE_JADED_LADY}.`
    },
    {
      name: 'The Jaded Lady',
      description: `Formerly known as ${THE_SEA_GHOST}, The Jaded Lady is a small, two-masted ship.`
    }
  ]
}

/**
 * @param {object} a
 * @param {string} a.name
 * @param {object} b
 * @param {string} b.name
 *
 * @returns {number}
 */
const sortByName = (a, b) => {
  if (a.name > b.name) {
    return 1
  } else {
    return -1
  }
}

const extractName = (md) => {
  const index = md.indexOf(']')
  if (index === -1) {
    return md
  }
  return md.substring(1, index)
}

const characterSummary = (character) => {
  const species = character.species ? `${character.species}` : ''
  const work = character.work && character.work !== 'Unknown' ? `${extractName(character.work)}` : ''
  return (species && work) ? `${species} | ${work}` : (species || work)
}

/**
 *
 * @type {object}
 * @property {object[]} events
 * @property {string} events[].header
 * @property {string} events[].content
 * @property {string} events[].id
 * @property {object[]} characters
 * @property {string} characters[].name
 * @property {number} characters[].sentiment
 * @property {string} characters[].species
 * @property {object} characters[].faction
 * @property {string} characters[].faction.name
 * @property {string} characters[].faction.link
 * @property {object} characters[].work
 * @property {JSX.Element} characters[].image
 * @property {string} characters[].description
 * @property {string} characters[].id
 * @property {object[]} locations
 * @property {string} locations[].name
 * @property {string} locations[].description
 * @property {object[]} locations[].locations
 * @property {string} locations[].locations[].name
 * @property {string} locations[].locations[].description
 * @property {string} locations[].id
 * @property {object[]} factions
 * @property {string} factions[].name
 * @property {string} factions[].description
 * @property {object[]} factions[].members
 * @property {string} factions[].members[].name
 * @property {string} factions[].members[].faction
 * @property {string} factions[].members[].id
 * @property {string} factions[].id
 * @property {object[]} deities
 * @property {string} deities[].name
 * @property {string[]} deities[].aliases
 * @property {string[]} deities[].domains
 * @property {JSX.Element} deities[].image
 * @property {string} deities[].description
 * @property {string} deities[].id
 * @property {object[]} ships
 * @property {string} ships[].name
 * @property {string} ships[].description
 * @property {string} ships[].id
 */
export const dataWithIds = {
  events: [
    ...data.events.map((event, index) => {
      return {
        ...event,
        id: `${event.header}-${index}`
      }
    })
  ],
  characters: [
    ...data.characters.map((character, index) => {
      return {
        ...character,
        id: index.toString(),
        summary: characterSummary(character)
      }
    }).sort(sortByName)
  ],
  locations: [
    ...data.locations.map((location, index) => {
      return {
        ...location,
        locations: location.locations?.map((sublocation, index) => {
          return {
            ...sublocation,
            workers: [
              ...data.characters.map((character, index) => {
                return {
                  name: character.name,
                  work: character.work,
                  id: index,
                  image: character.image
                }
              }).filter(character => character.work && extractName(character.work) === sublocation.name)
            ],
            id: index.toString()
          }
        }),
        id: index.toString(),
        workers: [
          ...data.characters.map((character, index) => {
            return {
              name: character.name,
              work: character.work,
              id: index,
              image: character.image
            }
          }).filter(character => character.work && extractName(character.work) === location.name)
        ]
      }
    }).sort(sortByName)

  ],
  factions: [
    ...data.factions.map((faction, index) => {
      return {
        ...faction,
        id: index.toString(),
        totalSentiment: data.characters.filter(character => character.faction && character.faction.name === faction.name && character.sentiment).reduce((total, character) => total + character.sentiment, 0),
        members: [
          ...data.characters.map((character, index) => {
            return {
              name: character.name,
              faction: character.faction,
              id: index,
              image: character.image
            }
          }).filter(character => character.faction && character.faction.name === faction.name)]
      }
    }).sort(sortByName)
  ],
  deities: [
    ...data.deities.map((deity, index) => {
      return {
        ...deity,
        id: index.toString()
      }
    }).sort(sortByName)
  ],
  ships: [
    ...data.ships.map((ship, index) => {
      return {
        ...ship,
        id: index.toString()
      }
    }).sort(sortByName)
  ]
}

export const dataWithoutImages = () => {
  const dataWithoutImages = { ...dataWithIds }
  dataWithoutImages.characters = dataWithoutImages.characters.map(character => {
    delete character.image
    return character
  })
  dataWithoutImages.deities = dataWithoutImages.deities.map(deity => {
    delete deity.image
    return deity
  })
  return dataWithoutImages
}
