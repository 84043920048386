import { dataWithIds } from '../Data'

/**
 * @param {string} locationId
 * @returns {object} location
*/
export const getLocation = (locationId) => {
  const locations = dataWithIds.locations
  const location = locations.find(location => location.id === locationId)
  const locationType = {
    id: location.id || '',
    name: location.name || '',
    description: location.description || '',
    image: location.image || null,
    locations: location.locations || [],
    workers: location.workers || []
  }
  return locationType
}
