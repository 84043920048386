import { dataWithIds } from '../Data'

/**
 * @param {string} deityId
 * @returns {object} deity
 */
export const getDeity = (deityId) => {
  const deities = dataWithIds.deities
  const deity = deities.find(deity => deity.id === deityId)
  const deityType = {
    id: deity.id || '',
    name: deity.name || '',
    description: deity.description || '',
    image: deity.image || null,
    domains: deity.domains || [],
    alignment: deity.alignment || ''
  }
  return deityType
}

/**
 * Return any deities that have a domain that matches any of the domains passed in
 *
 * @param {object} domains
 * @returns {object} deities
 */
export const getDeitiesByDomains = (domains) => {
  const deities = dataWithIds.deities
  const deitiesByDomain = deities.filter(deity => {
    return deity.domains.some(domain => {
      return domains.includes(domain)
    })
  }
  ).map(deity => {
    const deityType = {
      id: deity.id || '',
      name: deity.name || '',
      description: deity.description || '',
      image: deity.image || null,
      domains: deity.domains || [],
      alignment: deity.alignment || ''
    }
    return deityType
  })
  return deitiesByDomain
}

export const getDeitiesByCloseAlignment = (alignment) => {
  const deities = dataWithIds.deities
  const alignmentA = alignment[0]
  const alignmentB = alignment[1]
  const deitiesByAlignment = deities.filter(deity => {
    return deity.alignment.includes(alignmentA) || deity.alignment.includes(alignmentB)
  }
  ).map(deity => {
    const deityType = {
      id: deity.id || '',
      name: deity.name || '',
      description: deity.description || '',
      image: deity.image || null,
      domains: deity.domains || [],
      alignment: deity.alignment || ''
    }
    return deityType
  })
  return deitiesByAlignment
}

const filterDeities = (deity, primeDeity) => {
  return deity.domains.some(domain => primeDeity.domains.includes(domain)) &&
         deity.id !== primeDeity.id &&
         (deity.alignment.includes(primeDeity.alignment[0]) || deity.alignment.includes(primeDeity.alignment[1]))
}

export const getRelatedDeities = (primeDeity) => {
  const relatedDeities = dataWithIds.deities.filter(deity => filterDeities(deity, primeDeity))
    .map(deity => {
      const deityType = {
        id: deity.id || '',
        name: deity.name || '',
        description: deity.description || '',
        image: deity.image || null,
        domains: deity.domains || [],
        alignment: deity.alignment || ''
      }
      return deityType
    })
  return relatedDeities
}
