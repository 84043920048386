import { dataWithIds } from '../Data'

/**
 * @param {string} search
 * @returns {array} allOptions
 */
export const useSearch = (search) => {
  const allOptions =
    [
      ...dataWithIds.characters.map((item) => {
        const link = `characters/${item.id}`
        return {
          name: item.name,
          link
        }
      }),
      ...dataWithIds.deities.map((item) => {
        const link = `deities/${item.id}`
        return {
          name: item.name,
          link
        }
      }),
      ...dataWithIds.factions.map((item) => {
        const link = `factions/${item.id}`
        return {
          name: item.name,
          link
        }
      }),
      ...dataWithIds.locations.map((item) => {
        const link = `locations/${item.id}`
        return {
          name: item.name,
          link
        }
      }),
      ...dataWithIds.ships.map((item) => {
        const link = `ships/${item.id}`
        return {
          name: item.name,
          link
        }
      })
    ].sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      }
      return 0
    })

  if (search === '' || search === undefined || search === null) {
    return allOptions
  }
  const filteredOptions = allOptions.filter((option) => {
    return option.name.toLowerCase().includes(search.toLowerCase())
  })
  return filteredOptions
}
