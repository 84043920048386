import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getCharacter } from '../hooks/getCharacter'
import { getFaction } from '../hooks/getFaction'
import { getLocation } from '../hooks/getLocation'
import { LG, MD, XL, Paragraph, XXXL } from '@zendeskgarden/react-typography'
import { Avatar } from '@zendeskgarden/react-avatars'
import { NavExpandedContext } from './Root.js'
import { getDeity, getRelatedDeities } from '../hooks/getDeity'
import { SearchElement } from '../components/SearchElement'
import styled, { ThemeContext } from 'styled-components'
import { LabelLink } from '../components/LabelLink'
import { Description } from '../components/Description'
import { getShip } from '../hooks/getShip'
import { Alignment } from '../components/Alignment'
import { Domains } from '../components/Domains'
import { getSublocation } from '../hooks/getSublocation'
import WorldMap from '../images/World_Map_2.png'
import { Anchor } from '@zendeskgarden/react-buttons'
import { Statuses } from '../components/Statuses'
import { Sentiment } from '../components/Sentiment'

const StyleWorldMapContainer = styled.div`
  position: relative;
  text-align: center;
  color: white;
  margin-top: 2rem;
  max-width: 256px;
  max-height: 256px;
  margin-left: auto;
  margin-right: auto;
`

const StyledWorldMapText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:${props => props.theme.palette.grey[100]};
`

const WorldMapImage = () => <img src={WorldMap} alt="World Map" />

const StyleWorldMapImage = styled(WorldMapImage)`
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`

const WorldMapWithText = () => (
  <StyleWorldMapContainer>
    <StyleWorldMapImage />
    <StyledWorldMapText>
      <Anchor href={WorldMap} isExternal>
        <XL>View the World Map</XL>
      </Anchor>
    </StyledWorldMapText>
  </StyleWorldMapContainer>
)

/**
 *
 * @returns {JSX.Element} blank page
 */
export const BlankPage = () => {
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(true)
  return (
    <Paragraph />
  )
}

/**
 *
 * @returns {JSX.Element} error page
 */
export const ErrorPage = () => {
  return (
    <>
      <Paragraph>
        <LG>Sorry, looks like an error occured.</LG>
      </Paragraph>
      <Paragraph>
        <MD>
          <Link to="/">Go home</Link>
        </MD>
      </Paragraph>
    </>
  )
}

/**
 *
 * @returns {JSX.Element} character page
 */
export const CharacterPage = () => {
  const { characterId } = useParams()
  const character = getCharacter(characterId)
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(false)

  return (
    <>
      {character.image}
      <Paragraph><LG>{character.name}</LG></Paragraph>
      <Statuses status={character.status} sentiment={character.sentiment} />
      <Paragraph><MD><Description description={character.description} /></MD></Paragraph>
      {character.species && <Paragraph><MD>Species: {character.species}</MD></Paragraph>}
      <Paragraph><MD>Workplace: <Description description={character.work} /></MD></Paragraph>
      <Paragraph><MD>Faction: <LabelLink to={character.faction.link} label={character.faction.name} /></MD></Paragraph>
    </>
  )
}

/**
 *
 * @returns {JSX.Element} faction page
 */
export const FactionPage = () => {
  const { factionId } = useParams()
  const faction = getFaction(factionId)
  const theme = useContext(ThemeContext)
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(false)
  return (
    <>
      {faction.image}
      <Paragraph><LG>{faction.name}</LG></Paragraph>
      <Paragraph><MD><Description description={faction.description} /></MD></Paragraph>
      {faction.totalSentiment && <Paragraph><Sentiment sentiment={faction.totalSentiment} /></Paragraph>}
      {faction.members.length > 0 && <Paragraph><MD>Known Members:</MD></Paragraph>}
      {faction.members.map((member) =>
        <Paragraph key={member.id}>
          <Link to={`../characters/${member.id}`}>
            <Avatar size="small" backgroundColor={theme.palette.grey[600]}>
              {member.image
                ? member.image
                : <Avatar.Text>
                  {firstAndLastInitials(member.name)}
                </Avatar.Text>
              }
            </Avatar>
            <StyledName>{member.name}</StyledName>
          </Link>
        </Paragraph>
      )}
    </>
  )
}

export const ShipPage = () => {
  const { shipId } = useParams()
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(false)
  const ship = getShip(shipId)
  return (
    <>
      <Paragraph><LG>{ship.name}</LG></Paragraph>
      <Paragraph><MD><Description description={ship.description} /></MD></Paragraph>
    </>
  )
}

/**
 *
 * @returns {JSX.Element} deity page
 */
export const DeityPage = () => {
  const { deityId } = useParams()
  const theme = useContext(ThemeContext)
  const deity = getDeity(deityId)
  const relatedDeities = getRelatedDeities(deity)
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(false)
  return (
    <>
      {deity.image}
      <Paragraph><LG>{deity.name}</LG></Paragraph>
      <Paragraph><MD>Alignment: <Alignment alignmentInitials={deity.alignment} /></MD></Paragraph>
      <Paragraph><MD>Domains: <Domains domains={deity.domains} /></MD></Paragraph>
      <Paragraph><MD><Description description={deity.description} /></MD></Paragraph>
      {relatedDeities.length > 0 && <Paragraph><MD>Related Deities:</MD></Paragraph>}
      {relatedDeities.map((deity) =>
        <Paragraph key={deity.id}>
          <Link to={`../deities/${deity.id}`}>
            <Avatar size="small" isSystem backgroundColor={deity.image ? theme.palette.grey[100] : theme.palette.grey[600]} >
              {deity.image
                ? deity.image
                : <Avatar.Text>
                  {firstAndLastInitials(deity.name)}
                </Avatar.Text>
              }
            </Avatar>
            <StyledName>{deity.name}</StyledName>
          </Link>
        </Paragraph>
      )}
    </>
  )
}

const StyledName = styled.span`
  margin-left: 0.5rem;
`

/**
 * @param {string} name
 * @returns {string} first and last initials
 */
const firstAndLastInitials = (name) => {
  const nameArray = name.split(' ')
  const firstInitial = nameArray[0][0]
  if (nameArray.length === 1) {
    return `${firstInitial}`
  }
  const lastInitial = nameArray[nameArray.length - 1][0]
  return `${firstInitial}${lastInitial}`
}

/**
 *
 * @returns {JSX.Element} location page
 */
export const LocationPage = () => {
  const { locationId } = useParams()
  const location = getLocation(locationId)
  const setIsExpanded = useContext(NavExpandedContext)
  const theme = useContext(ThemeContext)
  setIsExpanded(false)
  return (
    <>
      {location.image}
      <Paragraph><LG>{location.name}</LG></Paragraph>
      <Paragraph><MD><Description description={location.description} /></MD></Paragraph>
      {location.locations.map((location) =>
        <Paragraph key={location.id}><Link to={`${location.id}`}>
          <Avatar size="small" backgroundColor={theme.palette.grey[600]}>
            {location.image
              ? location.image
              : <Avatar.Text>
                {firstAndLastInitials(location.name)}
              </Avatar.Text>
            }
          </Avatar>
          <StyledName>
            {location.name}
          </StyledName>
        </Link>
        </Paragraph>
      )}
      {location.workers.length > 0 && <Paragraph><MD>Known Workers:</MD></Paragraph>}
      {location.workers.map((member) =>
        <Paragraph key={member.id}>
          <Link to={`../characters/${member.id}`}>
            <Avatar size="small" backgroundColor={theme.palette.grey[600]}>
              {member.image
                ? member.image
                : <Avatar.Text>
                  {firstAndLastInitials(member.name)}
                </Avatar.Text>
              }
            </Avatar>
            <StyledName>{member.name}</StyledName>
          </Link>
        </Paragraph>
      )}
    </>
  )
}

/**
 *
 * @returns {JSX.Element} sublocation page
 */
export const SublocationPage = () => {
  const { locationId, sublocationId } = useParams()
  const [locationName, sublocation] = getSublocation(locationId, sublocationId)
  const theme = useContext(ThemeContext)
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(false)
  return (
    <>
      <Paragraph><LG>{sublocation.name}</LG></Paragraph>
      <Paragraph><MD><Description description={sublocation.description} /></MD></Paragraph>
      <div style={{ width: '100%' }} />
      <Paragraph><LabelLink relative='path' to='..' label={`Back to ${locationName}`} /></Paragraph>
      {sublocation.workers.length > 0 && <Paragraph><MD>Known Workers:</MD></Paragraph>}
      {sublocation.workers.map((member) =>
        <Paragraph key={member.id}>
          <Link to={`../characters/${member.id}`}>
            <Avatar size="small" backgroundColor={theme.palette.grey[600]}>
              {member.image
                ? member.image
                : <Avatar.Text>
                  {firstAndLastInitials(member.name)}
                </Avatar.Text>
              }
            </Avatar>
            <StyledName>{member.name}</StyledName>
          </Link>
        </Paragraph>
      )}
    </>
  )
}

/**
 *
 * @returns {JSX.Element} search page
 */
export const SearchPage = () => {
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(true)
  return (
    <>
      <Paragraph>
        <SearchElement />
        <WorldMapWithText />
      </Paragraph>
    </>
  )
}

export const AlignmentTestPage = () => {
  return (
    <>
      <Alignment alignmentInitials="CN" />
      <Alignment alignmentInitials="CE" />
      <Alignment alignmentInitials="CG" />

      <Alignment alignmentInitials="LN" />
      <Alignment alignmentInitials="LE" />
      <Alignment alignmentInitials="LG" />

      <Alignment alignmentInitials="TN" />
      <Alignment alignmentInitials="NE" />
      <Alignment alignmentInitials="NG" />

      <Alignment alignmentInitials="XX" />
    </>
  )
}

/**
 *
 * @returns {JSX.Element} long test page
 */
export const LongTestPage = () => {
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(false)
  return (
    <>
      <Paragraph>
        <LG>
        lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quod quia, voluptatum, quae
        tetur adipisicing elit. Quisquam quod quia, voluptatum, quae voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus.
        voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus. Quisquam quod quia,
        voluptatum, quae voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus.
        </LG>
      </Paragraph>
      <Paragraph>
        <LG>
      lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quod quia, voluptatum, quae
      voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus. Quisquam quod quia,
      Quisquam quod quia, voluptatum, quae voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus.
      voluptatum, quae voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus.
        </LG>
      </Paragraph>
      <Paragraph>
        <LG>
    lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quod quia, voluptatum, quae
    voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus. Quisquam quod quia,
    sit amet consectetur adipisicing elit. Quisquam quod quia, voluptatum, quae
    voluptatum, quae voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus.
        </LG>
      </Paragraph>
      <Paragraph>
        <XXXL>
  uam quod quia, voluptatum, quae voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus.
  voluptatem, quibusdam quos voluptas quas quidem quae volupgsam quod quia, voluptatum, quae
  tatibus. Quisquam quod quia, voluptatum, quae voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus.
  lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quod quia, voluptatum, quae
  voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus. Quisquam quod quia,
  voluptatum, quae voluptatem, quibusdam quos voluptas quas quidem quae voluptatibus.
        </XXXL>
      </Paragraph>
    </>
  )
}
