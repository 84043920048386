import { Tag } from '@zendeskgarden/react-tags'
import React from 'react'

/**
 *
 * @param {object} props
 * @param {string[]} props.domains
 * @returns {JSX.Element} domains
 */

export const Domains = ({ domains }) => {
  const domainsOrUnknown = domains.length > 0 ? domains : ['Unknown']
  return domainsOrUnknown.map((domain) => (
    <Tag key={domain}>
      {domain}
    </Tag>
  ))
}
