const currentYear = 856

const currentMonth = 8

const currentDay = 14

/**
 *
 * @param {number} month
 * @param {number} day
 *
 * @returns {string} season
 */
export const dateToExandrianSeason = (month, day) => {
  const seasons = [
    { name: 'Winter', startMonth: 1, startDay: 1, endMonth: 3, endDay: 12 },
    { name: 'Spring', startMonth: 3, startDay: 13, endMonth: 5, endDay: 25 },
    { name: 'Summer', startMonth: 5, startDay: 26, endMonth: 8, endDay: 2 },
    { name: 'Autumn', startMonth: 8, startDay: 3, endMonth: 11, endDay: 1 },
    { name: 'Winter', startMonth: 11, startDay: 2, endMonth: 11, endDay: 32 }
  ]

  const season = seasons.find((season) => {
    if (season.startMonth === month) {
      return day >= season.startDay
    }
    if (season.endMonth === month) {
      return day <= season.endDay
    }
    if (season.startMonth < month && season.endMonth > month) {
      return true
    }
    return false
  })

  return season?.name || 'Unknown'
}

/**
 *
 * @param {number} month
 * @returns {string} month
 */
export const monthToExandrianMonth = (month) => {
  switch (month) {
  case 1:
    return 'Horisal'
  case 2:
    return 'Misuthar'
  case 3:
    return 'Dualahei'
  case 4:
    return 'Thunsheer'
  case 5:
    return 'Unndilar'
  case 6:
    return 'Brussendar'
  case 7:
    return 'Sydenstar'
  case 8:
    return 'Fessuran'
  case 9:
    return 'Quen\'pillar'
  case 10:
    return 'Cuersaar'
  case 11:
    return 'Duscar'
  default:
    return 'Unknown'
  }
}

const numberSuffix = (number) => {
  switch (number) {
  case 1:
    return 'st'
  case 2:
    return 'nd'
  case 3:
    return 'rd'
  default:
    return 'th'
  }
}

const exandrianMonths = [
  {
    name: 'Horisal',
    days: 29
  },
  {
    name: 'Misuthar',
    days: 30
  },
  {
    name: 'Dualahei',
    days: 30
  },
  {
    name: 'Thunsheer',
    days: 31
  },
  {
    name: 'Unndilar',
    days: 28
  },
  {
    name: 'Brussendar',
    days: 31
  },
  {
    name: 'Sydenstar',
    days: 32
  },
  {
    name: 'Fessuran',
    days: 29
  },
  {
    name: 'Quenpillar',
    days: 27
  },
  {
    name: 'Cuersaar',
    days: 29
  },
  {
    name: 'Duscar',
    days: 32
  }
]

export const dateToInteger = (day, month, year) => {
  let totalDays = -1
  for (let i = 0; i < month - 1; i++) {
    totalDays += exandrianMonths[i].days
  }
  totalDays += day
  totalDays += (year - 1) * 328
  return totalDays
}

export const integerToDate = (daysSinceEpoch) => {
  const year = Math.floor(daysSinceEpoch / 328) + 1
  let days = daysSinceEpoch % 328 + 1
  let month = 0
  while (days > exandrianMonths[month].days) {
    days -= exandrianMonths[month].days
    month++
  }
  month++
  return { day: days, month, year }
}

export const dateToString = (day, month, year) => {
  return `${monthToExandrianMonth(month)} ${day}${numberSuffix(day)}, ${year} PD`
}

export const currentDateToString = () => {
  return dateToString(currentDay, currentMonth, currentYear)
}

export const currentIntegerDate = dateToInteger(currentDay, currentMonth, currentYear)
