import { Paragraph } from '@zendeskgarden/react-typography'
import React, { useContext, useState } from 'react'
import { NavExpandedContext } from './Root.js'
import { Button } from '@zendeskgarden/react-buttons'

const pirateNamesA =
`Ed(mund)/Esmerelda
Roger(s)/Rachel
Jack/Janet
David/Davina
Pete(r)/Petra
Angel(ica)/Angelo
Sam(uel)/Samantha
Morgan/Marge
Diego/Diana
Ed(ward)/Edwina
Isabel(la)/Isaac
Charles/Chiara`

const pirateNamesB =
`James/Jim
Chris(topher)/Kit
Robert(s)/Rob
Francois/Frank
Juan/Juanita
John(athan)/Jackie
Butch(er)/Butchie
(Old )Ben/Benjamin
Will(iam)/Willie
Louis/Louise
Jean/Jeanette
Stede/Steph(anie)`

const pirateNamesC =
`Meathook/Methuselah
Jose/Joseph
Fernando/Ferdinand
Henry/Henri
Mary/Marietta
Anne/Anna
Philip/Phil
Scraggs/Scraggy
Eliza(beth)/Liz
Hector/Hectora
Genny/Jenny
Thom(as)/Tom`

const pirateMasculineFirstNames =
`Esteban
Richard
Hendrik
Raymond
John
Edmund
Charles
Peter
Olivier
Barth
Henry
Roger
Don
Martin
Louis
Fredrick
Willem
Nicholas
Jerry
Edward
Alvaro
Gaspar
Francisco
Johan
Carlos
Francis
Jacques
Jack
Francois
Silas
Thomas
Jacob
Juan
Phillipe
Jean
Wiiliam/Billy`

const pirateFeminineFirstNames =
`Bridget
Juliette
Esther
Rose
Beatrice
Olive
Antonia
Charlotte
Isabel
Adine
Angela
Cecile
Edwidge
Catalina
Elizabeth
Madeleine
Anastasia
Emma
Mary
Francisca
Ana
Agnes
Marie
Eleanor
Anne
Henrietta
Alice
Margaret
Jeannette
Camela
Catherine
Ursula
Anette
Gabriel
Esme
Marion`

const pirateSurnames =
`Perez
Thompson
Jansen
Williams
Alva
Dubois
Leon
Brown
Jones
Johnson
Thatch
Davies
Archer
Blanc
Evans
Wright
Smith
Wilson
Bernard
Roberts
White
Jean
Santiago
Morel
Rodriguez
Garcia
Robinson
Lopez
Baker
Black
Bonnet
Walker
Martin
Jackson
Diaz
Taylor`

const pirateNicknames =
`Sir/Madam
Sea
Turtle
Siren
Red
One-Eye/One-Arm/One-Leg
Crimson
Blue
Water
Skull
Tall-Tale
Old
Blood
Mr./Mrs./Miss
Gunpowder
King/Queen
Bow-Legged
Fish
Whale
Bones
Squid
Scurvy
Bilge
Shark
Heart
The Bride/The Groom
Black
White
Death
Dark
Devil
Knife
Claw
Rat
Green
Planktooth`

/**
 *
 * @param {string[]} pirateNames
 * @returns {string} pirate name
 */
const pirateNameGenerator = (pirateNames) => {
  let pirateNameTemplate = pirateNames[Math.floor(Math.random() * pirateNames.length)]

  if (pirateNameTemplate.includes('(')) {
    if (Math.random() > 0.5) {
      pirateNameTemplate = pirateNameTemplate.replace(/\(.*\)/, '')
    } else {
      pirateNameTemplate = pirateNameTemplate.replace(/[()]/g, '')
    }
  }

  if (pirateNameTemplate.includes('/')) {
    pirateNameTemplate = pirateNameTemplate.split('/')
    pirateNameTemplate = pirateNameTemplate[Math.floor(Math.random() * pirateNameTemplate.length)]
  }

  return pirateNameTemplate
}

/**
 *
 * @param {string[]} extraNames
 * @param {string[]} firstNames
 * @param {string[]} middleNames
 * @param {string[]} lastNames
 * @returns {string} pirate name
 */
const generateFullPirateNameNoRepeats = (extraNames, firstNames, middleNames, lastNames) => {
  const fullList = extraNames.split('\n').filter((name) => {
    return Math.random() > 0.5
  })

  const firstNamesArray = firstNames.split('\n')
  const firstName = pirateNameGenerator(firstNamesArray.concat(fullList))

  const middleNamesArray = middleNames.split('\n')
  const middleName = pirateNameGenerator(middleNamesArray.concat(fullList))

  const lastNamesArray = lastNames.split('\n')
  const lastName = pirateNameGenerator(lastNamesArray.concat(fullList))

  return `${firstName} "${middleName}" ${lastName}`
}

const generateNames = () => {
  let list = []
  for (let i = 0; i < 12; i++) {
    list = list.concat(generateFullPirateNameNoRepeats(
      pirateNamesA + '\n' + pirateNamesB + '\n' + pirateNamesC,
      pirateFeminineFirstNames + '\n' + pirateMasculineFirstNames,
      pirateNicknames,
      pirateSurnames))
  }
  return list
}

/**
 *
 * @returns {JSX.Element} generator page
 */
export const GeneratorPage = () => {
  const setIsExpanded = useContext(NavExpandedContext)
  const [names, setNames] = useState([], generateNames())
  setIsExpanded(false)
  return (
    <>
      <Button onClick={() => setNames(generateNames())}>
        Generate Names
      </Button>
      <Paragraph>
        {names.map((name, index) => <Paragraph key={`${index}-${name}`}>{name}</Paragraph>)}
      </Paragraph>
    </>
  )
}
