import { dataWithIds } from '../Data'

/**
 * @param {string} locationId
 * @param {string} sublocationId
 * @returns {object} deity
 */
export const getSublocation = (locationId, sublocationId) => {
  const locations = dataWithIds.locations
  const location = locations.find(location => location.id === locationId)
  const sublocation = location.locations.find(sublocation => sublocation.id === sublocationId)
  const sublocationType = {
    id: sublocation.id || '',
    name: sublocation.name || '',
    description: sublocation.description || '',
    workers: sublocation.workers || []
  }
  const locationName = location.name || ''
  return [locationName, sublocationType]
}
