import { dataWithIds } from '../Data'

/**
 * @param {string} characterId
 * @returns {object} character
 */
export const getCharacter = (characterId) => {
  const emptyCharacter = {
    id: characterId,
    name: '',
    sentiment: undefined,
    description: '',
    work: 'Unknown',
    species: '',
    faction: {
      name: 'Unknown',
      link: ''
    },
    image: null
  }
  const characters = dataWithIds.characters
  const character = characters.find(character => character.id === characterId)
  return {
    ...emptyCharacter,
    ...character
  }
}

/**
 * @param {string} characterId
 * @returns {object} character
 */
export const getCharacterStrict = (characterId) => {
  const characters = dataWithIds.characters
  const character = characters.find(character => character.id === characterId)
  const characterType = {
    id: characterId.id || '',
    name: character.name || '',
    sentiment: character.sentiment || undefined,
    description: character.description || '',
    species: character.species || '',
    faction: {
      name: character.faction.name || 'Unknown',
      link: character.faction.link || ''
    },
    image: character.image || null
  }
  return characterType
}
