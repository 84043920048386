import { dataWithIds } from '../Data'

/**
 * @param {string} shipId
 * @returns {object} ship
 *
 * */
export const getShip = (shipId) => {
  const ships = dataWithIds.ships
  const ship = ships.find(ship => ship.id === shipId)
  const shipType = {
    id: ship.id || '',
    name: ship.name || '',
    description: ship.description || ''
  }
  return shipType
}
