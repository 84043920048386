import React, { useContext, useState } from 'react'
import { NavExpandedContext } from './Root'
import { Button } from '@zendeskgarden/react-buttons'
import { Paragraph } from '@zendeskgarden/react-typography'
import { Item } from '../pages/Shop'
import { nonLegendaryItem } from '../data/Items'

const generateItem = () => {
  return nonLegendaryItem(Math.random, 5)[0]
}

/**
 *
 * @returns {JSX.Element} generator page
 */
export const ItemGenerator = () => {
  const setIsExpanded = useContext(NavExpandedContext)
  const [item, setItem] = useState(undefined)
  setIsExpanded(false)
  return (
    <>
      <Button onClick={() => setItem(nonLegendaryItem(Math.random, 5)[0])}>
        Generate Item
      </Button>
      <Paragraph>
        {item && <Item item={item} />}
      </Paragraph>
    </>
  )
}
